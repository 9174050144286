import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Upload } from 'antd'
import PropTypes from 'prop-types'
import './styles.less'
import { FileDoneOutlined } from '@ant-design/icons'

function FileUploader({ name, form, setDeletedItems, mini, ...props }) {
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (props.fileList) {
      setFileList(props.fileList)
    }
  }, [props.fileList])

  const uploadButton = (
    <>
      <Button>
        {form.getFieldValue(name) ? 'Cambiar archivo' : 'Agregar archivo'}
      </Button>
      {/* show file url in new tab */}
      {form.getFieldValue(name) &&
        typeof form.getFieldValue(name) === 'string' &&
        form.getFieldValue(name)?.startsWith('http') && (
          <Tooltip title='Abrir archivo'>
            <a
              href={form.getFieldValue(name)}
              target='_blank'
              onClick={(e) => {
                e.stopPropagation()
              }}
              style={{ marginLeft: 30 }}
              rel='noopener noreferrer'
            >
              {/* icon file */}
              <FileDoneOutlined style={{ fontSize: '20px' }} />
            </a>
          </Tooltip>
        )}
    </>
  )

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
    form.setFieldsValue({ [name]: newFileList })
  }

  return (
    <div>
      <Upload
        name={name}
        accept='pdf/* image/* .docx'
        previewFile={false}
        showUploadList={{
          showDownloadIcon: false,
          previewIcon: false,
          showPreviewIcon: false
        }}
        {...props}
        fileList={fileList}
        maxCount={1}
        multiple={false}
        onChange={handleChange}
      >
        {uploadButton}
      </Upload>
    </div>
  )
}

FileUploader.defaultProps = {
  name: 'file'
}

FileUploader.propTypes = {
  name: PropTypes.string,
  fileList: PropTypes.array,
  hideButton: PropTypes.bool,
  mini: PropTypes.bool,
  loading: PropTypes.bool,
  onRemove: PropTypes.func
}

export default FileUploader
