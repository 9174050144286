import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'config/constants';

export const certificationsApi = createApi({
  reducerPath: 'certificationsApi',
  baseQuery,
  endpoints: (builder) => ({
    getCertifications: builder.query({
      query: () => `/certifications`,
    }),
    createCertification: builder.mutation({
      query: (body) => ({
        url: `/certifications`,
        method: 'POST',
        body,
      }),
    }),
    updateCertification: builder.mutation({
      query: ({ id, values }) => ({
        url: `/certifications/${id}`,
        method: 'PATCH',
        body: values,
      }),
    }),
    deleteCertification: builder.mutation({
      query: (id) => ({
        url: `/certifications/${id}`,
        method: 'DELETE',
      }),
    }),
    sortCertifications: builder.mutation({
      query: (certifications) => ({
        url: `/certifications/sort`,
        method: 'POST',
        body: {
          certifications,
        },
      }),
    }),
  }),
});

export const {
  useGetCertificationsQuery,
  useCreateCertificationMutation,
  useUpdateCertificationMutation,
  useDeleteCertificationMutation,
  useSortCertificationsMutation,
} = certificationsApi;
