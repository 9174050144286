import React from 'react';
import { MailOutlined } from '@ant-design/icons';
import Contacts from 'pages/Contacts';

const contactsRoutes = {
  title: 'Contactos',
  icon: <MailOutlined />,
  Component: Contacts,
  path: '/contactos',
  sidebar: true,
};

export default contactsRoutes;
