import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();

const _baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getState().auth.accessToken;
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

export const baseQuery = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await _baseQuery(args, api, extraOptions);
  if (
    result.error &&
    (result.error.status === 401 || result.error.status === 403)
  ) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      api.dispatch({ type: 'auth/logoutApp' });
      release();
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await _baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const REQUISITION_STATUSES = {
  pending: 'Pendientes',
  accepted: 'Aceptados',
  canceled: 'Cancelados',
};

export const MODAL = {
  ActionConfirm: 'ActionConfirm',
  ItemAddOrEdit: 'ItemAddOrEdit',
  CategoryAddOrEdit: 'CategoryAddOrEdit',
  ColorAddOrEdit: 'ColorAddOrEdit',
  CertificationAddOrEdit: 'CertificationAddOrEdit',
  SubCategoryAddOrEdit: 'SubCategoryAddOrEdit',
  QRDownload: 'QRDownload',
  RestobarEdit: 'RestobarEdit',
  ProductBin: 'ProductBin',
  OrderCategories: 'OrderCategories',
  ScheduleEdit: 'ScheduleEdit',
};

export const MODELS = {
  product: 'product',
  category: 'category',
  color: 'color',
  certification: 'certification',
};

export const CATEGORY_ICONS = [
  'faHamburger',
  'faCocktail',
  'faBeer',
  'faWineBottle',
  'faCoffee',
  'faPizzaSlice',
  'faIceCream',
  'faHotdog',
  'faFish',
  'faCheese',
  'faSeedling',
  'faStroopwafel',
  'faPepperHot',
  'faCandyCane',
  'faAppleAlt',
  'faLeaf',
];
