import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Select } from 'antd';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';

import {
  useUpdateCertificationMutation,
  useCreateCertificationMutation,
} from 'store/services/certificationsApi';
import { useGetAllProductsQuery } from 'store/services/productApi';
import FileUploader from 'components/Common/FileUploader';
import MultiCheckboxPopup from 'components/MultiCheckboxPopup';
import './styles.less';

const CertificationAddOrEdit = NiceModal.create(({ certification }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const modal = useModal();
  const { data: products } = useGetAllProductsQuery();

  const [form] = Form.useForm();

  const [createCertification, { isLoading: creating }] =
    useCreateCertificationMutation();
  const [updateCertification, { isLoading: updating }] =
    useUpdateCertificationMutation();

  const itemsOptions = useMemo(() => {
    return (
      products?.map((item) => ({
        // label: `${item.sku} - ${item.name}`,
        label: (
          <>
            <div className='certification-option-sku'>{item.sku}</div>{' '}
            <div className='certification-option-name'>{item.name}</div>
          </>
        ),
        value: item.id,
        searchString: `${item.sku} - ${item.name}`,
      })) || []
    );
  }, [products]);

  console.log('itemsOptions', itemsOptions);

  const handleResolve = () => {
    modal.resolve();
    modal.hide();
  };

  const handleFinish = async (values) => {
    const formData = new FormData();
    console.log('values.file', values.file);
    formData.append('name', values.name);
    formData.append('file', values.file[0].originFileObj);
    formData.append('products', selectedItems.join(','));
    if (certification?.id) {
      await updateCertification({
        id: certification.id,
        values: formData,
      }).then(handleResolve);
    } else {
      await createCertification(formData).then(handleResolve);
    }
  };

  useEffect(() => {
    if (!modal.visible) return;
    if (certification) {
      form.setFieldsValue({
        ...certification,
        products: certification.products.map((item) => item.id),
      });
      setSelectedItems(certification?.products?.map((item) => item.id));
    }
  }, [certification, modal.visible, form]);

  return (
    <>
      <Modal
        {...antdModal(modal)}
        title={`${
          certification?.id ? 'Editar certificación' : 'Nueva certificación'
        } ${certification?.id ? certification.name : ''}`}
        onOk={() => form.submit()}
        open={modal.visible}
        style={{ minWidth: '750px' }}
        onCancel={modal.hide}
        afterClose={modal.remove}
        okText='Guardar'
        cancelText='Cancelar'
        getContainer={false}
        okButtonProps={{ loading: creating || updating }}
      >
        <>
          <Form
            layout='vertical'
            form={form}
            onFinish={(values) => handleFinish(values)}
            onValuesChange={(v, a) => console.log(a)}
            initialValues={{
              name: null,
              file: null,
              products: [],
            }}
          >
            <Form.Item
              label='Nombre'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'El nombre del producto es obligatorio',
                },
              ]}
            >
              <Input placeholder='Ej: Módulo Interruptor'></Input>
            </Form.Item>

            <Form.Item
              label='Productos'
              name='products'
              rules={[{ required: true }]}
            >
              <MultiCheckboxPopup
                search
                buttonText={`Seleccionados`}
                checkedList={selectedItems}
                onChange={setSelectedItems}
                filterOptions={itemsOptions}
              />
            </Form.Item>

            <Form.Item
              label='Archivo'
              name='file'
              rules={[
                {
                  required: true,
                  message: 'El nombre del producto es obligatorio',
                },
              ]}
            >
              <FileUploader name='file' form={form} />
            </Form.Item>
          </Form>
        </>
      </Modal>
    </>
  );
});

CertificationAddOrEdit.propTypes = {
  items: PropTypes.array,
  certification: PropTypes.object,
};

export default CertificationAddOrEdit;
