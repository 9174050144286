import { useMemo } from 'react'
import { useGetSettingsQuery } from 'store/services/settingsApi'

export const useContacts = () => {
  const { data, isLoading, isFetching } = useGetSettingsQuery()

  const contacts = useMemo(() => {
    if (!data) return {}
    const setting = data.find((item) => item.key === 'contacts')
    return setting?.value ? setting.value : {}
  }, [data])

  return {
    contacts,
    isLoading: isLoading || isFetching
  }
}
