import React from 'react';
import { ShoppingOutlined } from '@ant-design/icons';
import Home from 'pages/Home';

const homeRoutes = {
  title: 'Productos',
  icon: <ShoppingOutlined />,
  Component: Home,
  path: '/productos',
  sidebar: true,
};

export default homeRoutes;
