import React from 'react';
import './styles.less';
const StepContent = ({ children, visible }) => {
  return (
    <div className={`step-content-container ${!visible ? 'hidden' : ''}`}>
      {children}
    </div>
  );
};

export default StepContent;
