import React from 'react';
import { Input, Form } from 'antd';
import Button from 'components/UI/Button';
import './styles.less';

function LoginForm({ onFinish, isLoading }) {
  return (
    <Form
      name='basic'
      className='login-form'
      onFinish={onFinish}
      validateTrigger={'onSubmit'}
    >
      <Form.Item
        required
        name='email'
        rules={[{ required: true, type: 'email' }]}
      >
        <Input placeholder='Correo electrónico' />
      </Form.Item>

      <Form.Item
        name='password'
        rules={[{ required: true, message: 'Ingrese una contraseña' }]}
      >
        <Input.Password placeholder='Contraseña' />
      </Form.Item>

      <Form.Item>
        <Button loading={isLoading} htmlType='submit' className='resto-button'>
          Ingresar
        </Button>
      </Form.Item>
    </Form>
  );
}

export default LoginForm;
