import storage from 'reduxjs-toolkit-persist/lib/storage';
// import { restobarApi } from 'store/services/restobarApi';

export const reduxPersistConfig = {
  active: true,
  reducerVersion: 1,
  storage,
  key: 'root',
  whitelist: ['auth'],
  // blacklist: [restobarApi.reducerPath],
};
