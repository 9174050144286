import React from 'react'
import { Row, Col, Typography, Table, Button, Space, Popconfirm } from 'antd'

import { isDesktop, isMobile } from 'react-device-detect'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  useDeleteColorMutation,
  useGetColorsQuery
  // useUpdateColorMutation,
} from 'store/services/colorsApi'
import { MODAL } from 'config/constants'
import { useModal } from '@ebay/nice-modal-react'

const Colors = () => {
  const { data: colors, isLoading, refetch } = useGetColorsQuery()
  const [deleteColor, { isLoading: deleting }] = useDeleteColorMutation()
  // const [updateColor, { isLoading: updating }] = useUpdateColorMutation();
  const actionModal = useModal(MODAL.ColorAddOrEdit)

  const handleActionModal = (color) => {
    actionModal
      .show({
        color
      })
      .then(refetch)
  }

  const handleDelete = async (id) => {
    await deleteColor(id)
    refetch()
  }

  const columns = [
    { title: 'Id', width: 60, render: (row) => row.id },
    {
      title: 'Nombre',
      render: (row) => row.name
    },
    {
      width: 40,
      render: (color) => (
        <Space size='small'>
          <Button
            size='small'
            type='link'
            icon={<EditOutlined />}
            onClick={() => handleActionModal(color)}
          ></Button>
          <Popconfirm
            title='¿Eliminar color?'
            okText='Eliminar'
            onConfirm={() => handleDelete(color.id)}
          >
            <Button
              size='small'
              type='link'
              danger
              icon={<DeleteOutlined />}
            ></Button>
          </Popconfirm>
        </Space>
      )
    }
  ]

  return (
    <>
      <Row gutter={isDesktop ? [20, 20] : [0, 0]}>
        <Col span={24}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Configuración de colores
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Row justify='end'>
            <Col>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => handleActionModal()}
              >
                {!isMobile ? 'Nuevo' : undefined}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Table
            dataSource={colors || []}
            columns={columns}
            loading={isLoading || deleting}
          />
        </Col>
      </Row>
    </>
  )
}

export default Colors
