import React, { useState } from 'react';
import { Row, Col, Typography, Table, Tabs, Input, Button } from 'antd';
import { REQUISITION_STATUSES } from 'config/constants';
import { useGetRequisitionsQuery } from 'store/services/requisitionApi';
import moment from 'moment';

const Requisition = () => {
  const [params, setParams] = useState({
    status: 'pending',
    page: 1,
  });
  const { data: requisitions, isFetching } = useGetRequisitionsQuery(params);
  const { Title } = Typography;

  const columns = [
    {
      title: 'Id de la solicitud',
      dataIndex: 'id',
      render: (data) => data,
    },
    {
      title: 'Fecha',
      dataIndex: 'createdAt',
      render: (data) => moment(data).format('DD-MM-YYYY HH:MM'),
    },
    {
      title: 'Empresa',
      dataIndex: 'enterprise',
      render: (data) => <b>{data}</b>,
      width: 250,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend'],
    },
    {
      title: 'Cliente',
      dataIndex: 'name',
      render: (data) => data,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (data) => data,
    },
    {
      title: 'Teléfono',
      dataIndex: 'phone',
      render: (data) => data,
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      render: (data) =>
        data === 'pending' ? (
          <Button type='primary'>{REQUISITION_STATUSES[data]}</Button>
        ) : (
          <b>{REQUISITION_STATUSES[data]}</b>
        ),
      align: 'right',
    },
  ];

  const TableWithSearch = () => {
    return (
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Table
            size='middle'
            columns={columns}
            dataSource={requisitions?.items}
            rowKey={(record) => record.id}
            loading={isFetching}
            pagination={{
              current: params.page,
              total: requisitions?.meta?.totalItems,
              pageSize: requisitions?.meta?.itemsPerPage,
              onChange: (page) => setParams({ ...params, page }),
            }}
          />
        </Col>
      </Row>
    );
  };

  const { TabPane } = Tabs;

  return (
    <>
      <Title level={3}>Solicitudes</Title>
      <Row>
        <Col>
          <Input.Search placeholder='Buscar...' />
        </Col>
      </Row>
      <Tabs
        defaultActiveKey='0'
        onChange={(t) => setParams({ ...params, status: t, page: 1 })}
      >
        {Object.keys(REQUISITION_STATUSES).map((status) => (
          <TabPane tab={REQUISITION_STATUSES[status]} key={status}>
            <TableWithSearch />
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

export default Requisition;
