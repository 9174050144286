import React from 'react';
import { Input, Form } from 'antd';
import Button from 'components/UI/Button';
import './styles.less';

function ActivateForm({ onFinish }) {
  const [form] = Form.useForm();
  return (
    <Form
      name='basic'
      className='activate-form'
      form={form}
      onFinish={onFinish}
      validateTrigger={'onSubmit'}
    >
      <Form.Item
        name='password'
        rules={[
          {
            required: true,
            message: 'Ingrese su contraseña',
          },
          {
            min: 6,
            message: 'Tu contraseña debe tener al menos 6 caracteres',
          },
        ]}
      >
        <Input.Password placeholder='Contraseña' />
      </Form.Item>

      <Form.Item
        name='password2'
        rules={[
          {
            required: true,
            message: 'Ingrese contraseña',
          },
          {
            min: 6,
            message: 'Tu contraseña debe tener al menos 6 caracteres',
          },
          {
            validateTrigger: 'onSubmit',
            validator: (rule, value) => {
              const password = form.getFieldValue('password');
              console.log(password, value);
              if (password === value) return Promise.resolve();
              return Promise.reject('Las contraseñas no coinciden');
            },
          },
        ]}
      >
        <Input.Password placeholder='Repetir Contraseña' />
      </Form.Item>

      <Form.Item>
        <Button htmlType='submit' className='resto-button'>
          Activar cuenta
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ActivateForm;
