import React from 'react';
import PropTypes from 'prop-types';

const Grid = ({
  gridTemplateColumns,
  gridAutoColumns,
  gridAutoRows,
  gridGap,
  children,
  onClick,
  flex,
  justify,
  alignContent,
  alignItems,
  style,
  className,
  width,
  direction,
  ...props
}) => (
  <div
    onClick={onClick}
    style={{
      display: flex ? 'flex' : 'grid',
      gridGap: gridGap || 0,
      gap: gridGap || 0,
      gridTemplateColumns: gridTemplateColumns || '1fr',
      gridAutoRows: gridAutoRows || 'min-content',
      gridAutoColumns: gridAutoColumns,
      justifyContent: justify || 'flex-start',
      justifyItems: justify,
      alignContent: alignContent || 'flex-start',
      alignItems: alignItems || 'flex-start',
      width: width || '100%',
      flexDirection: direction,
      direction: direction,
      ...style,
    }}
    className={className}
    {...props}
  >
    {children}
  </div>
);

Grid.propTypes = {
  gridGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridTemplateColumns: PropTypes.string,
  gridAutoColumns: PropTypes.string,
  gridAutoRows: PropTypes.string,
  justify: PropTypes.string,
  alignContent: PropTypes.string,
  alignItems: PropTypes.string,
  flex: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.number,
  direction: PropTypes.string,
  props: PropTypes.any,
};
export default Grid;
