import { useEffect } from 'react';
import { Row, Col, message, Card } from 'antd';
import LoginForm from 'components/Auth/LoginForm';
import { useLoginMutation } from 'store/services/authApi';
import { useHistory } from 'react-router-dom';
import logo from 'assets/castillo.png';

const Login = () => {
  const history = useHistory();
  const [login, { data, isLoading, isSuccess, isError, error }] =
    useLoginMutation();

  const onFinish = async (values) => {
    try {
      await login(values);
      history.push('/');
    } catch (err) {}
  };

  useEffect(() => {
    if (isSuccess) {
      message.success(data.message);
    } else if (isError) {
      message.error(error?.data?.message || 'Ha ocurrido un error');
    }
  }, [data, isSuccess, isError, error]);

  return (
    <Card>
      <Row justify='center' align='middle' style={{ flexDirection: 'column' }}>
        <Col>
          <img src={logo} alt='castillo' />
        </Col>
        <Col>
          <LoginForm onFinish={onFinish} isLoading={isLoading} />
        </Col>
      </Row>
    </Card>
  );
};

export default Login;
