import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'config/constants'

export const colorApi = createApi({
  reducerPath: 'colorApi',
  baseQuery,
  endpoints: (builder) => ({
    getColors: builder.query({
      query: () => `/colors`
    }),
    createColor: builder.mutation({
      query: (body) => ({
        url: `/colors`,
        method: 'POST',
        body
      })
    }),
    update: builder.mutation({
      query: ({ id, values }) => ({
        url: `/colors/${id}`,
        method: 'PATCH',
        body: values
      })
    }),
    deleteColor: builder.mutation({
      query: (id) => ({
        url: `/colors/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetColorsQuery,
  useCreateColorMutation,
  useUpdateMutation,
  useDeleteColorMutation
} = colorApi
