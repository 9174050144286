import React from 'react'
import { Row, Col, Typography, Table, Button, Card, Dropdown } from 'antd'

import { isDesktop, isMobile } from 'react-device-detect'
import {
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  MoreOutlined,
  PlusOutlined
} from '@ant-design/icons'

import { MODAL } from 'config/constants'
import { useModal } from '@ebay/nice-modal-react'
import {
  useGetCertificationsQuery,
  useDeleteCertificationMutation
} from 'store/services/certificationsApi'
import Result from 'components/Result'
import { faFile } from '@fortawesome/free-solid-svg-icons'

const Certifications = () => {
  const {
    data: certifications,
    isLoading,
    refetch
  } = useGetCertificationsQuery()
  const [deleteCertification, { isLoading: deleting }] =
    useDeleteCertificationMutation()

  const actionModal = useModal(MODAL.CertificationAddOrEdit)

  const handleActionModal = (certification) => {
    actionModal
      .show({
        certification
      })
      .then(refetch)
  }

  const handleDelete = async (id) => {
    await deleteCertification(id)
    refetch()
  }

  const columns = [
    { title: 'sku', width: 120, render: (row) => row.sku },
    {
      title: 'Nombre del producto',
      render: (row) => row.name
    }
  ]

  return (
    <>
      <Row gutter={isDesktop ? [20, 20] : [0, 0]}>
        <Col span={24}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Certificaciones
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Row justify='end'>
            <Col>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => handleActionModal()}
              >
                {!isMobile ? 'Nuevo' : undefined}
              </Button>
            </Col>
          </Row>
        </Col>

        {certifications?.length ? (
          certifications.map((certification) => (
            <Col span={24}>
              <Card
                title={certification.name}
                extra={
                  <Dropdown
                    trigger={['click']}
                    menu={{
                      items: [
                        {
                          icon: <FilePdfOutlined />,
                          label: 'Ver certificación',
                          key: 'certi',
                          onClick: () =>
                            window.open(certification?.file, '_blank')
                        },
                        {
                          icon: <EditOutlined />,
                          label: 'Editar',

                          key: 'edit',
                          onClick: () => handleActionModal(certification)
                        },
                        {
                          icon: <DeleteOutlined />,
                          label: 'Eliminar',
                          key: 'delete',
                          onClick: () => handleDelete(certification.id),
                          danger: true
                        }
                      ]
                    }}
                  >
                    <Button icon={<MoreOutlined />} />
                  </Dropdown>
                }
              >
                <Table
                  dataSource={certification.products || []}
                  columns={columns}
                  loading={isLoading || deleting}
                />
              </Card>
            </Col>
          ))
        ) : (
          <Result
            icon={faFile}
            buttonText='Crear certificación'
            title='Aún no has creado certificaciones'
            onClick={() => handleActionModal()}
          />
        )}
      </Row>
    </>
  )
}

export default Certifications
