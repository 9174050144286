import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authApi } from 'store/services/authApi';
import { restobarApi } from 'store/services/restobarApi';
import { logoutApp } from 'store/slices/authSlice';

export const useAuth = () => {
  const authStore = useSelector((state) => ({
    accessToken: state.auth.accessToken,
    user: state.auth.user,
  }));

  const dispatch = useDispatch();

  const logout = useCallback(() => {
    dispatch(logoutApp());
    dispatch(restobarApi.util.resetApiState());
    dispatch(authApi.util.resetApiState());
  }, [dispatch]);

  return useMemo(
    () => ({
      ...authStore,
      logout,
    }),
    [authStore, logout]
  );
};
