import NiceModal from '@ebay/nice-modal-react';
import { MODAL } from 'config/constants';
import ActionConfirm from './ActionConfirm';
import ItemAddOrEdit from './ItemAddOrEdit';
import CategoryAddOrEdit from './CategoryAddOrEdit';
import ColorAddOrEdit from './ColorAddOrEdit';
import CertificationAddOrEdit from './CertificationAddOrEdit';
import SubCategoryAddOrEdit from './SubCategoryAddOrEdit';
import QRDownload from './QRDownload';
import RestobarEdit from './RestobarEdit';
import ProductBin from './ProductBin';
import OrderCategories from './OrderCategories';
import ScheduleEdit from './ScheduleEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  NiceModal.register(MODAL.ActionConfirm, ActionConfirm);
  NiceModal.register(MODAL.ItemAddOrEdit, ItemAddOrEdit);
  NiceModal.register(MODAL.CategoryAddOrEdit, CategoryAddOrEdit);
  NiceModal.register(MODAL.ColorAddOrEdit, ColorAddOrEdit);
  NiceModal.register(MODAL.CertificationAddOrEdit, CertificationAddOrEdit);
  NiceModal.register(MODAL.SubCategoryAddOrEdit, SubCategoryAddOrEdit);
  NiceModal.register(MODAL.QRDownload, QRDownload);
  NiceModal.register(MODAL.RestobarEdit, RestobarEdit);
  NiceModal.register(MODAL.ProductBin, ProductBin);
  NiceModal.register(MODAL.OrderCategories, OrderCategories);
  NiceModal.register(MODAL.ScheduleEdit, ScheduleEdit);
};
