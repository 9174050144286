import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Input, Modal, Form, Switch, Select } from 'antd'
import './styles.less'
import {
  useCreateCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation
} from 'store/services/categoryApi'
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react'
import slugify from 'slugify'

const CategoryAddOrEdit = NiceModal.create(({ category }) => {
  const [form] = Form.useForm()
  const modal = useModal()
  const [createCategory, { isLoading: creating }] = useCreateCategoryMutation()
  const [updateCategory, { isLoading: updating }] = useUpdateCategoryMutation()
  const { isLoading } = useGetCategoriesQuery()

  const handleResolve = () => {
    modal.resolve()
    modal.hide()
  }

  const handleFinish = async (values) => {
    const data = {
      ...values,
      order: 1,
      slug: slugify(values.name).toLowerCase(),
      productRef: values.productRef || null
    }

    if (category) {
      await updateCategory({ id: category.id, values: data }).then(
        handleResolve
      )
    } else {
      await createCategory(data).then(handleResolve)
    }
  }

  useEffect(() => {
    if (!modal.visible) return
    category &&
      form.setFieldsValue({
        ...category,
        productRef: category.productRef?.id
      })
  }, [category, modal.visible, form])

  return (
    <>
      <Modal
        {...antdModal(modal)}
        title={category ? 'Editar categoría' : 'Nueva categoría'}
        open={modal.visible}
        onCancel={modal.hide}
        afterClose={modal.remove}
        onOk={() => form.submit()}
        getContainer={false}
        okButtonProps={{ loading: creating || updating }}
        okText='Guardar'
      >
        {!isLoading && (
          <Form
            form={form}
            layout='vertical'
            onFinish={(values) => handleFinish(values)}
            initialValues={{
              name: '',
              slug: '',
              parent: null,
              visible: true,
              available: true
            }}
          >
            <Form.Item
              label='Nombre'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'El nombre de la categoría es obligatorio'
                }
              ]}
            >
              <Input placeholder='Ej: Línea Toscana' />
            </Form.Item>

            <Form.Item
              name='visible'
              label='¿La categoría será visible para los clientes?'
              valuePropName='checked'
            >
              <Switch checkedChildren='Sí' unCheckedChildren='No' />
            </Form.Item>
            <Form.Item
              name='available'
              label='¿La categoría se mostrará como disponible para los clientes?'
              valuePropName='checked'
            >
              <Switch checkedChildren='Sí' unCheckedChildren='No' />
            </Form.Item>
            {category && (
              <Form.Item label='Producto de referencia' name='productRef'>
                <Select
                  showSearch
                  placeholder='Selecciona un producto'
                  allowClear
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={category.products.map((product) => ({
                    label: product.name,
                    value: product.id
                  }))}
                />
              </Form.Item>
            )}
          </Form>
        )}
      </Modal>
    </>
  )
})

CategoryAddOrEdit.propTypes = {
  category: PropTypes.object
}

export default CategoryAddOrEdit
