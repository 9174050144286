import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Steps,
  Switch,
  Space,
  Button
} from 'antd'
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react'
import './styles.less'
import {
  useCreateProductMutation,
  useUpdateProductMutation
} from 'store/services/productApi'
import { useGetColorsQuery } from 'store/services/colorsApi'
import FormItemImage from 'components/Common/FormItemImage'
import { compressImage } from 'utils'
import StepContent from 'components/StepContent'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import _ from 'lodash'
import slugify from 'slugify'

const ItemAddOrEdit = NiceModal.create(
  ({ categories, item, category, categoryLocked }) => {
    const modal = useModal()
    const [deletedItems, setDeletedItems] = useState([])
    const [current, setCurrent] = useState(0)

    const [form] = Form.useForm()
    const { Step } = Steps

    const [createProduct, { isLoading: creating }] = useCreateProductMutation()
    const [updateProduct, { isLoading: updating }] = useUpdateProductMutation()
    const { data: colors, isLoading: loadingColors } = useGetColorsQuery()

    // const colorOptions = [{ value: 1, label: 'Blanco' }];

    const colorOptions = useMemo(
      () => colors?.map((color) => ({ value: color.id, label: color.name })),
      [colors]
    )

    const categoriesDict = useMemo(() => {
      let dict = {}
      categories?.forEach((cat) => (dict[cat.id] = cat))
      return dict
    }, [categories])

    const categoriesOptions = useMemo(() => {
      const options = categories
        ?.filter((cat) => cat.parentId)
        .map((cat) => ({
          value: cat.id,
          label: `${
            categoriesDict[cat.parentId]
              ? `${categoriesDict[cat.parentId].name} - `
              : ''
          } ${cat.name}`
        }))
      return _.orderBy(options, ['label'], ['asc'])
    }, [categories, categoriesDict])

    const handleResolve = () => {
      modal.resolve()
      modal.hide()
    }

    const handleFinish = async (values) => {
      const formData = new FormData()
      formData.append('name', values.name)
      formData.append('sku', values.sku)
      values.ean13 && formData.append('ean13', values.ean13)
      values.color && formData.append('color', values.color)
      formData.append('price', values.price)
      formData.append('description', values.description)
      formData.append('order', 1)
      formData.append('slug', slugify(values.name, { lower: true }))
      formData.append('available', values.available)
      formData.append('visible', values.visible)
      formData.append('featured', values.featured)
      formData.append('category', values.category)

      formData.append('quantity_per_pack', values.quantity_per_pack)

      values.link_falabella &&
        formData.append('link_falabella', values.link_falabella)
      values.link_mercadolibre &&
        formData.append('link_mercadolibre', values.link_mercadolibre)

      formData.append('deleted_images', deletedItems.join(','))

      const techInfo = values?.tech_info?.length
        ? JSON.stringify(values.tech_info)
        : []

      formData.append('tech_info', techInfo)

      async function printFiles(files) {
        const list = await Promise.all(
          files.map(async (file) => {
            if (file.thumbUrl.startsWith('http')) return file.thumbUrl
            const contents = file.originFileObj
              ? await compressImage(file.originFileObj)
              : file.src
            return contents
          })
        )
        return list
      }

      if (values.images) {
        const filesList = await printFiles(values.images)

        for (let index = 0; index < filesList.length; index++) {
          formData.append(`images`, filesList[index])
        }
      }

      if (item?.id) {
        await updateProduct({ id: item.id, formData }).then(handleResolve)
      } else {
        await createProduct(formData).then(handleResolve)
      }
    }

    useEffect(() => {
      if (!modal.visible) return
      if (item) {
        console.log('item')
        form.setFieldsValue({
          ...item,
          category: item?.category?.id
            ? item.category.id
            : category?.id
            ? category.id
            : null,
          color: item?.color?.id ? item.color.id : null,
          images:
            item?.images?.map((image, i) => ({
              uid: i,
              thumbUrl: image,
              src: image
            })) || [],
          tech_info: Array.isArray(item.tech_info) ? item.tech_info : []
        })
      } else if (category) {
        form.setFieldsValue({ category: category.id })
      }
    }, [item, modal.visible, form, category])

    return (
      <>
        <Modal
          {...antdModal(modal)}
          title={`${item?.id ? 'Editar producto' : 'Nuevo producto'} ${
            item?.id ? item.name : ''
          }`}
          onOk={() => form.submit()}
          open={modal.visible}
          style={{ minWidth: '750px' }}
          onCancel={modal.hide}
          afterClose={modal.remove}
          okText='Guardar'
          cancelText='Cancelar'
          okButtonProps={{ loading: creating || updating || loadingColors }}
          maskClosable={false}
        >
          <>
            <Form
              layout='vertical'
              form={form}
              onFinish={(values) => handleFinish(values)}
              initialValues={{
                category: null,
                name: '',
                quantity_per_pack: null,
                price: 0,
                visible: true,
                available: true,
                featured: false,
                description: '',
                tech_info: []
              }}
            >
              <Steps
                size='small'
                progressDot
                current={current}
                onChange={(value) => setCurrent(value)}
                labelPlacement='vertical'
              >
                <Step key='general' title='General'></Step>
                <Step key='extra' title='Detalles'></Step>
                <Step key='tech_info' title='Especificaciones'></Step>
                <Step key='files' title='Imágenes'></Step>
                <Step key='links' title='Enlaces'></Step>
              </Steps>
              <StepContent visible={current === 0}>
                <Form.Item
                  label='Categoría'
                  name='category'
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder='Selecciona una opción...'
                    disabled={categoryLocked}
                    options={categoriesOptions}
                  />
                </Form.Item>
                <Form.Item
                  label='Nombre'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: 'El nombre del producto es obligatorio'
                    }
                  ]}
                >
                  <Input placeholder='Ej: Módulo Interruptor'></Input>
                </Form.Item>
                <Form.Item
                  label='SKU'
                  name='sku'
                  rules={[
                    {
                      required: true,
                      message: 'El sku del producto es obligatorio'
                    }
                  ]}
                >
                  <Input placeholder='Ej: PROD1'></Input>
                </Form.Item>
                <Form.Item
                  label='Cantidad de productos por embalaje'
                  name='quantity_per_pack'
                >
                  <Input placeholder='Ej: 20 y 40 unidades'></Input>
                </Form.Item>
                <Form.Item label='EAN-13' name='ean13'>
                  <Input placeholder='Ingresa un EAN-13'></Input>
                </Form.Item>
                <Form.Item label='Color' name='color'>
                  <Select
                    placeholder='Selecciona un color'
                    options={colorOptions}
                    allowClear
                  />
                </Form.Item>
                <Form.Item
                  label='Precio'
                  name='price'
                  rules={[{ type: 'number', min: 0 }]}
                >
                  <InputNumber
                    formatter={(value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    style={{ minWidth: 150 }}
                    min={0}
                    max={99999999999}
                  />
                </Form.Item>
                <Form.Item
                  name='visible'
                  label='Visible en el catálogo'
                  valuePropName='checked'
                >
                  <Switch checkedChildren='Si' unCheckedChildren='No' />
                </Form.Item>
                <Form.Item
                  name='available'
                  label='Disponible para compra'
                  valuePropName='checked'
                >
                  <Switch checkedChildren='Si' unCheckedChildren='No' />
                </Form.Item>
                <Form.Item
                  name='featured'
                  label='Producto destacado'
                  valuePropName='checked'
                >
                  <Switch checkedChildren='Si' unCheckedChildren='No' />
                </Form.Item>
              </StepContent>
              <StepContent visible={current === 1}>
                <Form.Item
                  label='Descripción'
                  name='description'
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: 'La descripción del producto es obligatoria',
                  //   },
                  // ]}
                >
                  <CKEditor
                    editor={ClassicEditor}
                    data={item?.description}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log('Editor is ready to use!', editor);
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          'height',
                          '400px',
                          editor.editing.view.document.getRoot()
                        )
                      })
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      form.setFieldsValue({ description: data })
                      console.log({ event, editor, data })
                    }}
                    onBlur={(event, editor) => {
                      console.log('Blur.', editor)
                    }}
                    onFocus={(event, editor) => {
                      console.log('Focus.', editor)
                    }}
                  />
                </Form.Item>
              </StepContent>
              <StepContent visible={current === 2}>
                <Form.List name='tech_info'>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <Space
                          key={field.key}
                          style={{
                            display: 'flex',
                            marginBottom: 8
                          }}
                          align='center'
                        >
                          <Form.Item
                            label='Nombre'
                            {...field}
                            name={[field.name, 'name']}
                            fieldKey={[field.fieldKey, 'name']}
                            rules={[
                              {
                                required: true,
                                message: 'Este campo es obligatorio'
                              }
                            ]}
                          >
                            <Input
                              placeholder='Ej: Largo'
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                          <Form.Item
                            label='Valor'
                            {...field}
                            name={[field.name, 'value']}
                            fieldKey={[field.fieldKey, 'value']}
                            rules={[
                              {
                                required: true,
                                message: 'Este campo es obligatorio'
                              }
                            ]}
                          >
                            <Input
                              placeholder='10cm'
                              style={{ width: '100%' }}
                            />
                          </Form.Item>

                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type='dashed'
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Agregar campo
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </StepContent>
              <StepContent visible={current === 3}>
                <FormItemImage
                  name='images'
                  form={form}
                  setDeletedItems={setDeletedItems}
                />
              </StepContent>
              <StepContent visible={current === 4}>
                <Form.Item label='Link Falabella' name='link_falabella'>
                  <Input placeholder='Ej: https://www.falabella.com/'></Input>
                </Form.Item>
                <Form.Item label='Link Mercadolibre' name='link_mercadolibre'>
                  <Input placeholder='Ej: https://www.mercadolibre.com/'></Input>
                </Form.Item>
              </StepContent>
            </Form>
          </>
        </Modal>
      </>
    )
  }
)

ItemAddOrEdit.propTypes = {
  categories: PropTypes.array,
  item: PropTypes.object,
  category: PropTypes.object,
  categoryLocked: PropTypes.bool
}

export default ItemAddOrEdit
