import React, { useState } from 'react';
import {
  MenuOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Popover, Badge, Avatar } from 'antd';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import './styles.less';
import logo from 'assets/castillo.png';

function Navbar({ user, logout, collapsed, onCollapseLeftSide }) {
  const [fullscreen, setFullscreen] = useState(false);

  const toggleFullScreen = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
      setFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      setFullscreen(false);
    }
  };

  const classnames = cx('navbar', {
    'navbar-sm': collapsed,
  });

  const userDropDownProps = {
    logout,
  };

  return (
    <header className={classnames}>
      <div className='navbar-branding'>
        <span
          className='toggle_sidemenu_l'
          onClick={() => onCollapseLeftSide()}
        >
          <MenuOutlined />
        </span>
        <Link className='navbar-brand' to='/'>
          <img src={logo} alt='logo-castillo' />
        </Link>
      </div>
      {!isMobile && (
        <ul className='nav navbar-nav navbar-left clearfix'>
          <li onClick={toggleFullScreen}>
            <div className='request-fullscreen nav-link'>
              {fullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            </div>
          </li>
        </ul>
      )}
      <ul className='nav navbar-nav navbar-right clearfix'>
        <li className='dropdown'>
          <Popover
            placement='bottomRight'
            title={`Hola, ${user?.name}`}
            overlayClassName='navbar-popup bg-danger'
            content={<UserDropDown {...userDropDownProps} />}
            trigger='click'
          >
            <div className='nav-link dropdown-toggle'>
              <Badge dot>
                <Avatar>{user?.name}</Avatar>
              </Badge>
            </div>
          </Popover>
        </li>
      </ul>
    </header>
  );
}

const UserDropDown = (props) => {
  return (
    <ul className='dropdown-menu list-group '>
      <li onClick={props.logout} className='list-group-item dropdown-footer'>
        <b>
          <PoweroffOutlined type='poweroff' /> Salir
        </b>
      </li>
    </ul>
  );
};

UserDropDown.propTypes = {
  logout: PropTypes.func,
};

Navbar.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func,
  collapsed: PropTypes.bool,
  onCollapseLeftSide: PropTypes.func,
};

export default Navbar;
