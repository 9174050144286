import _ from 'lodash';
import { format, unformat } from 'currency-formatter';
import qs from 'query-string';
import {
  faHamburger,
  faCocktail,
  faBeer,
  faWineBottle,
  faCoffee,
  faPizzaSlice,
  faIceCream,
  faHotdog,
  faFish,
  faCheese,
  faSeedling,
  faStroopwafel,
  faPepperHot,
  faCandyCane,
  faAppleAlt,
  faLeaf,
  faShoppingBag,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useEffect } from 'react';

export const merge = (obj, data = {}) => Object.assign({}, obj, { ...data });

export const filterRoutes = (routes, { user, enterprise }) => {
  if (!user) return routes;
  const _routes = _.cloneDeep(routes);
  return _routes
    .map((route) => {
      if (route?.condition && !route?.condition?.({ user, enterprise })) {
        return route.condition({ user, enterprise });
      }
      if (route.subRoutes) {
        route.subRoutes = route.subRoutes.filter((subRoute) => {
          if (subRoute.condition) {
            return subRoute.condition({ user, enterprise });
          }
          return true;
        });
      }
      return route;
    })
    .filter((x) => !!x);
};

export const currencyFormat = (amount) => {
  return format(amount, {
    symbol: '$',
    code: 'CLP',
    precision: 0,
    format: '0',
    forbiddenChars: ['.', ','],
  });
};

export const currencyUnFormat = (amount) => {
  return unformat(amount, {
    symbol: '$',
    code: 'CLP',
    precision: 0,
    format: '0',
    forbiddenChars: ['.', ','],
  });
};

export function isNumeric(str) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export const iconRender = (icon) => {
  switch (icon) {
    case 'faHamburger':
      return faHamburger;
    case 'faCocktail':
      return faCocktail;
    case 'faBeer':
      return faBeer;
    case 'faWineBottle':
      return faWineBottle;
    case 'faCoffee':
      return faCoffee;
    case 'faPizzaSlice':
      return faPizzaSlice;
    case 'faIceCream':
      return faIceCream;
    case 'faHotdog':
      return faHotdog;
    case 'faFish':
      return faFish;
    case 'faCheese':
      return faCheese;
    case 'faSeedling':
      return faSeedling;
    case 'faStroopwafel':
      return faStroopwafel;
    case 'faPepperHot':
      return faPepperHot;
    case 'faCandyCane':
      return faCandyCane;
    case 'faAppleAlt':
      return faAppleAlt;
    case 'faLeaf':
      return faLeaf;
    default:
      return faShoppingBag;
  }
};

export const transformId = (title) => {
  const string = title.replace(/ /g, '-').toLowerCase();
  return string;
};

export const addQuery = (obj) => {
  return _.isEmpty(obj) ? '' : `?${qs.stringify(obj)}`;
};

export const compressImage = (file, width = 1000) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
      const imgElement = new Image();
      imgElement.src = event.target.result;
      imgElement.onload = function (e) {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = width;
        const scaleSize = MAX_WIDTH / e.target.width;
        canvas.width = MAX_WIDTH;
        canvas.height = e.target.height * scaleSize;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);
        ctx.canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg');
      };
    };
  });
};

export const useResizeObserver = (ref) => {
  const [dimensions, setDimensions] = useState(null);
  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setDimensions(entry.contentRect);
      });
    });
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);

  return dimensions;
};
