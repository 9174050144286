import React from 'react';
import { FormatPainterOutlined } from '@ant-design/icons';
import Colors from 'pages/Colors';

const attributesRoutes = {
  title: 'Colores',
  icon: <FormatPainterOutlined />,
  Component: Colors,
  path: '/colores',
  sidebar: true,
};

export default attributesRoutes;
