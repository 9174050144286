/* eslint-disable */
import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Typography,
  Card,
  Form,
  Input,
  Space,
  Button,
  Switch,
} from 'antd';
import MainLoading from 'components/MainLoading';
import _ from 'lodash';
import { isDesktop, isMobile } from 'react-device-detect';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useContacts } from 'hooks/useContacts';
import {
  useCreateSettingsMutation,
  useUpdateSettingsMutation,
} from 'store/services/settingsApi';

const Contacts = () => {
  const [form] = Form.useForm();
  const { contacts, isLoading } = useContacts();
  const [createSetting] = useCreateSettingsMutation();
  const [updateSetting] = useUpdateSettingsMutation();

  const handleSave = (values) => {
    if (_.isEmpty(contacts)) {
      createSetting({
        key: 'contacts',
        value: values,
      });
    } else {
      updateSetting({
        key: 'contacts',
        value: values,
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue(contacts);
  }, [contacts]);

  return (
    <>
      <Row gutter={isDesktop ? [20, 20] : [0, 0]}>
        <Col span={24}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Configuración de contactos
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            initialValues={contacts}
            layout='vertical'
            onFinish={handleSave}
          >
            {isLoading ? (
              <MainLoading />
            ) : (
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <Card title='Whatsapp'>
                    <Space>
                      <Form.Item name='whatsapp'>
                        <Input placeholder='Ingresa un número de contacto' />
                      </Form.Item>
                      <Form.Item
                        name='whatsapp_enabled'
                        valuePropName='checked'
                      >
                        <Switch
                          checkedChildren='Activado'
                          unCheckedChildren='Desactivado'
                        />
                      </Form.Item>
                    </Space>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card title='Correo para el formulario de contacto del sitio web'>
                    <Space>
                      <Form.Item name='email_inbox' rules={[{required: true, message: 'Este campo es requerido.'}]}>
                        <Input placeholder='Ingresa un correo' />
                      </Form.Item>
                      <Form.Item
                        name='email_inbox_enabled'
                        valuePropName='checked'
                      >
                        <Switch
                          checkedChildren='Activado'
                          unCheckedChildren='Desactivado'
                        />
                      </Form.Item>
                    </Space>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card title='Teléfonos'>
                    <Form.List name='phones'>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <Space
                              key={field.key}
                              style={{ display: 'flex', marginBottom: 8 }}
                              align='baseline'
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, 'number']}
                                fieldKey={[field.fieldKey, 'number']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Este campo es obligatorio',
                                  },
                                ]}
                              >
                                <Input placeholder='Ingresa un número de teléfono' />
                              </Form.Item>

                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type='dashed'
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Agregar otro
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card title='Correos para otras áreas como ventas, soporte, etc.'>
                    <Form.List name='emails'>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <Space
                              key={field.key}
                              style={{ display: 'flex', marginBottom: 8 }}
                              align='baseline'
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, 'address']}
                                fieldKey={[field.fieldKey, 'address']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Este campo es obligatorio',
                                  },
                                ]}
                              >
                                <Input placeholder='Ingresa un correo' />
                              </Form.Item>

                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type='dashed'
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Agregar otro
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Card>
                </Col>

                <Col span={24}>
                  <Card title='Correos de comercio exterior'>
                    <Form.List name='comex'>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <Space
                              key={field.key}
                              style={{ display: 'flex', marginBottom: 8 }}
                              align='baseline'
                            >
                              <Form.Item
                                {...field}
                                name={[field.name, 'address']}
                                fieldKey={[field.fieldKey, 'address']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Este campo es obligatorio',
                                  },
                                ]}
                              >
                                <Input placeholder='Ingresa un correo' />
                              </Form.Item>

                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type='dashed'
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Agregar otro
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card title='Direcciones'>
                    <Form.List name='addresses'>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field) => (
                            <Space
                              key={field.key}
                              style={{ display: 'flex', marginBottom: 8 }}
                              align='center'
                            >
                              <Form.Item
                                label='Nombre'
                                {...field}
                                name={[field.name, 'name']}
                                fieldKey={[field.fieldKey, 'name']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Este campo es obligatorio',
                                  },
                                ]}
                              >
                                <Input placeholder='Ingresa un nombre' />
                              </Form.Item>
                              <Form.Item
                                label='Dirección'
                                {...field}
                                name={[field.name, 'address']}
                                fieldKey={[field.fieldKey, 'address']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Este campo es obligatorio',
                                  },
                                ]}
                              >
                                <Input
                                  style={{ width: 400 }}
                                  placeholder='Ingresa una dirección'
                                />
                              </Form.Item>

                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type='dashed'
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Agregar otro
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card title='Horario de atención'>
                    <Form.Item label='Horarios' name='businessHours'>
                      <Input
                        style={{ maxWidth: 400 }}
                        placeholder='Ej: Lunes a Viernes de 8:00 a 15:00'
                      />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            )}
          </Form>
        </Col>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type='primary' onClick={() => form.submit()}>
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Contacts;
