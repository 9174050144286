import { createSlice } from '@reduxjs/toolkit'
import { authApi } from 'store/services/authApi'

const initialState = { accessToken: '', user: null }

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutApp(state) {
      state.accessToken = ''
      state.user = null
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.accessToken = payload.accessToken
        state.user = payload.user
      }
    )
    builder.addMatcher(
      authApi.endpoints.activateAccount.matchFulfilled,
      (state, { payload }) => {
        state.accessToken = payload.accessToken
        state.user = payload.user
      }
    )
    builder.addMatcher(
      authApi.endpoints.user.matchFulfilled,
      (state, { payload }) => {
        state.user = payload
      }
    )
  }
})

export const { logoutApp } = authSlice.actions
export default authSlice.reducer
