import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Table, Input } from 'antd';
import { useUpdateRestobarMutation } from 'store/services/restobarApi';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import moment from 'moment';
import { useUserQuery } from 'store/services/authApi';

const ScheduleEdit = NiceModal.create(({ openEditRestobar }) => {
  const [form] = Form.useForm();
  const modal = useModal();
  const [updateRestobar, { isLoading: updating }] = useUpdateRestobarMutation();
  const {
    data: { restobar },
  } = useUserQuery();
  useEffect(() => {
    if (!modal.visible) return;
    if (restobar?.openHours) {
      const initialData = Object.keys(restobar.openHours).reduce((acc, key) => {
        acc[`open-${key}`] = restobar.openHours[key].open;
        acc[`close-${key}`] = restobar.openHours[key].close;
        return acc;
      }, {});
      form.setFieldsValue({
        ...initialData,
      });
    }
  }, [restobar, modal.visible, form]);

  const handleResolve = () => {
    modal.resolve();
    modal.hide();
  };

  const handleFinish = async (values) => {
    let openHours = {};
    moment.weekdaysShort().forEach((day) => {
      openHours[day] = {
        open: values[`open-${day}`] || '',
        close: values[`close-${day}`] || '',
      };
    });

    const formData = new FormData();
    formData.append('openHours', JSON.stringify(openHours));

    updateRestobar({ id: restobar.id, formData }).then(handleResolve);
  };

  const columns = [
    {
      title: 'Día',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Inicio',
      dataIndex: 'open',
      key: 'open',
      render: (_, record) => (
        <Form.Item name={`open-${record.day}`} noStyle>
          <Input type='time' />
        </Form.Item>
      ),
    },
    {
      title: 'Termino',
      dataIndex: 'close',
      key: 'close',
      render: (_, record) => (
        <Form.Item name={`close-${record.day}`} noStyle>
          <Input type='time' />
        </Form.Item>
      ),
    },
  ];

  const data = useMemo(() => {
    let days = moment.weekdays();
    let daysShort = moment.weekdaysShort(); //always english
    daysShort = daysShort.reduce((acc, day, index) => {
      acc[days[index]] = day;
      return acc;
    }, {});
    days = days.map((day, index) => ({
      key: index,
      label: day,
      day: daysShort[day],
      open: '',
      close: '',
    }));
    return days;
  }, []);

  return (
    <>
      <Modal
        {...antdModal(modal)}
        title='Configurar horario'
        visible={modal.visible}
        onCancel={modal.hide}
        afterClose={() => {
          modal.remove();
          openEditRestobar();
        }}
        destroyOnClose
        onOk={() => form.submit()}
        okButtonProps={{ loading: updating }}
        okText='Guardar'
      >
        <Form form={form} onFinish={handleFinish}>
          <Table columns={columns} dataSource={data} pagination={false} />
        </Form>
      </Modal>
    </>
  );
});

ScheduleEdit.propTypes = {
  restobar: PropTypes.object,
  openEditRestobar: PropTypes.func,
};

export default ScheduleEdit;
