import React from 'react';
import PropTypes from 'prop-types';
import { Col, Divider, Row, Typography } from 'antd';
import Grid from 'components/Grid';
import './styles.less';

const Section = ({ title, children, extra }) => {
  return (
    <div className='section'>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Grid
            gridTemplateColumns='1fr max-content'
            alignItems='flex-start'
            gridGap={10}
            className='section-title'
          >
            <Typography.Title level={4}>{title}</Typography.Title>
            <div>{extra}</div>
          </Grid>
        </Col>
        <Col span={24}>{children}</Col>
      </Row>
      <Divider />
    </div>
  );
};
Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  extra: PropTypes.node,
};
export default Section;
