import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'reduxjs-toolkit-persist';
import { reduxPersistConfig } from 'config/reduxPersist';
// api services
// import { restobarApi } from './services/restobarApi';
import { categoryApi } from './services/categoryApi';
import { colorsApi } from './services/colorsApi';
import { settingsApi } from './services/settingsApi';
import { productApi } from './services/productApi';
import { requisitionApi } from './services/requisitionApi';
import { authApi } from './services/authApi';
import { menuApi } from './services/menuApi';
import { colorApi } from './services/colorApi';
import { certificationsApi } from './services/certificationsApi';
// slices
import authReducer from './slices/authSlice';

const reducers = combineReducers({
  [menuApi.reducerPath]: menuApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [colorsApi.reducerPath]: colorsApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [requisitionApi.reducerPath]: requisitionApi.reducer,
  [colorApi.reducerPath]: colorApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [certificationsApi.reducerPath]: certificationsApi.reducer,
  auth: authReducer,
});

const _persistedReducer = persistReducer(reduxPersistConfig, reducers);

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        /* ignore persistance actions */
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      menuApi.middleware,
      categoryApi.middleware,
      colorsApi.middleware,
      settingsApi.middleware,
      productApi.middleware,
      requisitionApi.middleware,
      authApi.middleware,
      colorApi.middleware,
      certificationsApi.middleware
    ),
});

export const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
