import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'config/constants';
import { addQuery } from 'utils';

export const requisitionApi = createApi({
  reducerPath: 'requisitionApi',
  baseQuery,
  endpoints: (builder) => ({
    getRequisitions: builder.query({
      query: (params) => `/contact/requisitions${addQuery(params)}`,
    }),
    createRequisition: builder.mutation({
      query: (body) => ({
        url: `/contact/requisitions`,
        method: 'POST',
        body,
      }),
    }),
    updateRequisition: builder.mutation({
      query: ({ id, values }) => ({
        url: `/contact/requisitions/${id}`,
        method: 'PATCH',
        body: values,
      }),
    }),
    deleteRequisition: builder.mutation({
      query: (id) => ({
        url: `/contact/requisitions/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetRequisitionsQuery,
  useCreateRequisitionMutation,
  useUpdateRequisitionMutation,
  useDeleteRequisitionMutation,
} = requisitionApi;
