import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';
import { store } from 'store';
import 'moment/locale/es';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { Provider } from 'react-redux';
import { persistor } from 'store';
import NiceModal from '@ebay/nice-modal-react';
import registerModals from 'components/Modals/registerModals';
import 'styles/index.less';
import moment from 'moment';

moment.locale('es', {
  weekdays: [
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo',
  ],
  weekdaysShort: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
});

registerModals();

ReactDOM.render(
  <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <NiceModal.Provider>
        <Routes />
      </NiceModal.Provider>
    </Provider>
  </PersistGate>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
