import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
// import themes from 'themes';
import './styles.less';
import { useResizeObserver } from 'utils';

const ScrollView = ({
  header,
  subHeader,
  children,
  card,
  borderedHeader,
  title,
  titleSize,
  style,
  extra,
}) => {
  const childrenRef = useRef();

  const titleStyles = {
    width: '100%',
    padding: 10,
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
  };
  const cardStyles = {
    borderRadius: 6,
    // boxShadow: themes[theme]['@card-box-shadow'],
    // background: themes[theme]['@component-background'],
  };
  const headerStyles = {
    // borderBottom: borderedHeader
    //   ? `1px solid ${themes[theme]['@border-color-split']}`
    //   : null,
    width: '100%',
    padding: card ? `10px 10px 10px 10px` : `0 0 10px 0`,
  };

  const Title =
    titleSize === 'big' ? (
      <Typography.Title level={4} strong>
        {title}
      </Typography.Title>
    ) : (
      <Typography.Text strong>{title}</Typography.Text>
    );

  const dimensions = useResizeObserver(childrenRef);

  return (
    <div
      className={`scrollview-container  ${card ? 'card' : null} ${
        title && 'titled'
      }`}
      style={card ? cardStyles : null}
    >
      {title && (
        <div style={titleStyles}>
          {Title} {extra && <div>{extra}</div>}
        </div>
      )}
      {header && <div style={headerStyles}>{header}</div>}
      {subHeader && (
        <div
          style={{
            ...headerStyles,
            width: dimensions?.right,
            padding: '8px 10px',
          }}
        >
          {subHeader}
        </div>
      )}

      <div
        className={`scrollview-body-container ${card && 'card'}`}
        style={style}
        ref={childrenRef}
      >
        {children}
      </div>
    </div>
  );
};

ScrollView.propTypes = {
  header: PropTypes.node,
  subHeader: PropTypes.node,
  children: PropTypes.node,
  card: PropTypes.bool,
  borderedHeader: PropTypes.bool,
  title: PropTypes.any,
  titleSize: PropTypes.string,
  style: PropTypes.object,
  extra: PropTypes.node,
};

export default ScrollView;
