import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'config/constants'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: `/auth/login`,
        method: 'POST',
        body
      })
    }),
    user: builder.query({
      query: () => `/auth/user`
    }),
    verifyActivate: builder.mutation({
      query: (body) => ({
        url: `/auth/verify-activate`,
        method: 'POST',
        body
      })
    }),
    activateAccount: builder.mutation({
      query: (body) => ({
        url: `/auth/activate-account`,
        method: 'POST',
        body
      })
    })
  })
})

export const {
  useLoginMutation,
  useUserQuery,
  useVerifyActivateMutation,
  useActivateAccountMutation
} = authApi
