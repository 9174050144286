import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from 'config/constants'
import { addQuery } from 'utils'

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery,
  endpoints: (builder) => ({
    getProductsByCategory: builder.query({
      query: (query) => {
        return `/products${addQuery(query)}`
      }
    }),
    getAllProducts: builder.query({
      query: (query) => {
        return `/products/all`
      }
    }),
    createProduct: builder.mutation({
      query: (formData) => ({
        url: `/products`,
        method: 'POST',
        headers: {
          Accept: 'multipart/form-data'
        },
        body: formData
      })
    }),
    updateProduct: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/products/${id}`,
        method: 'PATCH',
        headers: {
          Accept: 'multipart/form-data'
        },
        body: formData
      })
    }),
    deleteProduct: builder.mutation({
      query: (id) => ({
        url: `/products/${id}`,
        method: 'DELETE'
      })
    }),
    restoreProducts: builder.mutation({
      query: (data) => ({
        url: `/products/restore`,
        method: 'POST',
        body: data
      })
    })
  })
})

export const {
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductsByCategoryQuery,
  useGetAllProductsQuery,
  useUpdateProductMutation,
  useRestoreProductsMutation
} = productApi
