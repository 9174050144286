import React, { useEffect, useState } from 'react';
import NotFound from 'pages/NotFound';
import DefaultLayout from 'layouts/DefaultLayout';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import routes, { authRoutes } from './routes';
import AuthLayout from 'layouts/AuthLayout';
import { useAuth } from 'hooks/useAuth';
import { filterRoutes } from 'utils';

function Routes() {
  const { accessToken, user } = useAuth();
  const [userRoutes, setUserRoutes] = useState([]);
  useEffect(() => {
    setUserRoutes(filterRoutes(routes, { user }));
  }, [user]);
  return (
    <BrowserRouter>
      {accessToken ? (
        <Switch>
          <DefaultLayout>
            <Redirect exact from='/' to='/productos' />
            {/* default slash route */}
            {userRoutes.map((route, i) =>
              route.subRoutes ? (
                route.subRoutes.map((subRoute) => {
                  // console.log(subRoute);
                  return (
                    subRoute.path && (
                      <Route
                        exact
                        key={subRoute.path}
                        path={subRoute.path}
                        component={subRoute.Component}
                      />
                    )
                  );
                })
              ) : (
                <Route
                  exact
                  key={i}
                  path={route.path}
                  component={route.Component}
                />
              )
            )}
            <Route component={NotFound} />
          </DefaultLayout>
        </Switch>
      ) : (
        <Switch>
          <AuthLayout>
            {authRoutes.map((route, i) =>
              route.subRoutes ? (
                route.subRoutes.map(
                  (subRoute) =>
                    subRoute.path && (
                      <Route
                        exact
                        key={subRoute.path}
                        path={subRoute.path}
                        component={subRoute.Component}
                      />
                    )
                )
              ) : (
                <Route
                  exact
                  key={i}
                  path={route.path}
                  component={route.Component}
                />
              )
            )}
            <Redirect from='*' to='/auth/login' />
          </AuthLayout>
        </Switch>
      )}
    </BrowserRouter>
  );
}

export default Routes;
