import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Form, Row, Col, Typography, Button } from 'antd';
import { ScheduleOutlined } from '@ant-design/icons';
import { useUpdateRestobarMutation } from 'store/services/restobarApi';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import FormItemImage from 'components/Common/FormItemImage';
import { compressImage } from 'utils';
import ScheduleEdit from '../ScheduleEdit';

const RestobarEdit = NiceModal.create(({ restobar }) => {
  const [form] = Form.useForm();
  const modal = useModal();
  const scheduleModal = useModal(ScheduleEdit);
  const [updateRestobar, { isLoading: updating }] = useUpdateRestobarMutation();

  const handleResolve = () => {
    modal.resolve();
    modal.hide();
  };

  const handleFinish = async (values) => {
    const [file] = values.logo;
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('description', values.description);
    if (!file?.src) {
      formData.append('logo', await compressImage(file.originFileObj));
    }

    await updateRestobar({
      id: restobar.id,
      formData,
    }).then(handleResolve);
  };

  useEffect(() => {
    if (!modal.visible) return;
    restobar &&
      form.setFieldsValue({
        ...restobar,
        description: restobar.description || '',
        logo: [
          { uid: Date.now(), thumbUrl: restobar.logo, src: restobar.logo },
        ],
      });
  }, [restobar, modal.visible, form]);

  return (
    <>
      <Modal
        {...antdModal(modal)}
        visible={modal.visible}
        onCancel={modal.hide}
        afterClose={modal.remove}
        onOk={() => form.submit()}
        getContainer={false}
        okButtonProps={{ loading: updating }}
        okText='Guardar'
      >
        <Form
          layout='vertical'
          wrapperCol={24}
          form={form}
          onFinish={handleFinish}
        >
          <Row gutter={[20]} justify='center'>
            <Col span={24}>
              <FormItemImage name='logo' />
            </Col>
            <Col span={24}>
              <Typography.Title
                level={3}
                style={{ textAlign: 'center', marginBottom: 40 }}
              >
                {restobar.name}
              </Typography.Title>
              <Form.Item name='name' label='Nombre de tu restaurante'>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name='description'
                label='Ingresa una descripción de tu local'
              >
                <Input.TextArea
                  rows={4}
                  style={{ width: '100%', display: 'block' }}
                  maxLength={60}
                  showCount
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row align='middle' style={{ flexDirection: 'column' }}>
                <Button
                  type='ghost'
                  style={{ display: 'flex', alignItems: 'center', height: 100 }}
                  icon={<ScheduleOutlined style={{ fontSize: 60 }} />}
                  onClick={() => {
                    modal.hide();
                    scheduleModal.show({
                      restobar,
                      openEditRestobar: () => modal.show({ restobar }),
                    });
                  }}
                >
                  <Typography.Text strong>Horario de atención</Typography.Text>
                </Button>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
});

RestobarEdit.propTypes = {
  restobar: PropTypes.object,
};

export default RestobarEdit;
