import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography } from 'antd';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { MODELS } from 'config/constants';

const ActionConfirm = NiceModal.create(
  ({ okText, item, type, action, onConfirm }) => {
    const { Text } = Typography;
    const modal = useModal();

    const messages = {
      FEATURED: {
        title: `${
          item.featured
            ? 'Destacar producto'
            : 'Quitar producto de los destacados'
        }`,
        message: `¿Deseas marcar como ${
          item.featured ? 'no destacado' : 'destacado'
        } ${type === MODELS.product ? 'el producto' : 'la categoría'} ${
          item.name
        }?`,
        action: `Marcar como ${item.featured ? 'no destacado' : 'destacado'}`,
      },
      AVAILABLE: {
        title: `Marcar como ${item.available ? 'no disponible' : 'disponible'}`,
        message: `¿Deseas marcar como ${
          item.available ? 'no disponible' : 'disponible'
        } ${type === MODELS.product ? 'el producto' : 'la categoría'} ${
          item.name
        }?`,
        action: `Marcar como ${
          item.available ? 'no disponible' : 'disponible'
        }`,
        danger: item.available,
      },
      VISIBLE: {
        title: `${item.visible ? 'Ocultar' : 'Mostrar'}`,
        message: `¿Deseas ${item.visible ? 'ocultar' : 'mostrar'} ${
          type === MODELS.product ? 'el producto' : 'la categoría'
        } ${item.name}?`,
        action: `${item.visible ? 'Ocultar' : 'Mostrar'}`,
        danger: item.visible,
      },
      DELETE: {
        title:
          type === MODELS.product
            ? `Enviar a la papelera`
            : 'Eliminar categoría',
        message:
          type === MODELS.product
            ? `¿Deseas enviar ${item.name} a la papelera?`
            : `¿Deseas eliminar la categoría ${item.name}?. Todos los productos de esta categoría quedarán descategorizados.`,
        action: type === MODELS.product ? 'Enviar a la papelera' : 'Eliminar',
        danger: true,
      },
      FORBIDDEN: {
        title:
          type === MODELS.product
            ? `Enviar a la papelera`
            : 'Eliminar categoría',
        message:
          type === MODELS.product
            ? `No se puede enviar el producto ${item.name} a la papelera?`
            : `No se puede eliminar ${item.name} ya que contiene una o más subcategorías. Muévelas a otra categoria e intenta nuevamente.`,
        action: type === MODELS.product ? 'Enviar a la papelera' : 'Eliminar',
        danger: true,
      },
    };

    return (
      <>
        <Modal
          {...antdModal(modal)}
          closable={false}
          visible={modal.visible}
          onOk={async () => {
            await onConfirm();
            modal.hide();
            modal.resolve();
          }}
          onCancel={modal.hide}
          afterClose={modal.remove}
          okText={okText}
          title={messages[action].title}
          centered
          cancelText='Cancelar'
          okButtonProps={{
            type: messages[action].danger ? 'danger' : 'primary',
            hidden: action === 'FORBIDDEN',
          }}
        >
          <Text>{messages[action].message}</Text>
        </Modal>
      </>
    );
  }
);

ActionConfirm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  okText: PropTypes.string,
  item: PropTypes.object,
  type: PropTypes.string,
  danger: PropTypes.bool,
  onConfirm: PropTypes.func,
};

export default ActionConfirm;
