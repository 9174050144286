import { useCallback, useMemo, useState } from 'react'
import Fuse from 'fuse.js'
import { debounce } from 'throttle-debounce'

const useFuse = (list, options) => {
  const [search, updateSearch] = useState('')

  const { limit, matchAllOnEmptyQuery, ...fuseOptions } = options

  const fuse = useMemo(() => new Fuse(list, fuseOptions), [list, fuseOptions])

  // memoize results whenever the query or options change
  const results = useMemo(
    () =>
      !search && matchAllOnEmptyQuery
        ? fuse.getIndex().docs.slice(0, limit)
        : fuse.search(search, { limit }),
    [fuse, limit, matchAllOnEmptyQuery, search]
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setSearch = useCallback(debounce(100, updateSearch), [])

  const onSearch = useCallback(
    (e) => setSearch(e.target.value.trim()),
    [setSearch]
  )

  return {
    results,
    onSearch,
    search,
    setSearch
  }
}

export default useFuse
