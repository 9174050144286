import React from 'react'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import ImageUploader from './ImageUploader'

function FormItemImage({ name, form, setDeletedItems }) {
  const normFile = (e) => {
    console.log('Upload event:', e)
    return e.fileList
  }

  return (
    <Form.Item
      name={name}
      noStyle
      valuePropName='fileList'
      getValueFromEvent={normFile}
    >
      <ImageUploader
        name={name}
        form={form}
        setDeletedItems={setDeletedItems}
      />
    </Form.Item>
  )
}

FormItemImage.propTypes = {
  name: PropTypes.string.isRequired
}

export default FormItemImage
