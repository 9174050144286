import React from 'react';
import { Typography, Button, Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import './styles.less';
const Result = ({ title, description, onClick, buttonText, icon }) => {
  const { Title, Paragraph } = Typography;
  return (
    <div className='result-container'>
      <div className='result-content'>
        <Row align='middle' justify='center' gutter={[16, 40]}>
          <Col span={24}>
            <div className='icon-container'>
              <FontAwesomeIcon
                icon={icon || faShoppingBag}
                size='3x'
                className='icon'
              />
            </div>
          </Col>
          <Col span={24}>
            <Title level={4} style={{ textAlign: 'center', color: 'white' }}>
              {title}
            </Title>
            <Paragraph style={{ textAlign: 'center', color: 'white' }}>
              {description}
            </Paragraph>
          </Col>
          {onClick && (
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button type='primary' onClick={onClick}>
                {buttonText}
              </Button>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Result;
