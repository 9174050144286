import React from 'react';
import { Button as AntButton } from 'antd';
import cn from 'classnames';
import './styles.less';

const Button = (props) => {
  return (
    <AntButton className={cn('resto-button', props.className)} {...props}>
      {props.children}
    </AntButton>
  );
};

export default Button;
