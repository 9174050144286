import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Col, Modal, Row, Spin } from 'antd'
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react'
import {
  useGetProductsByCategoryQuery,
  useRestoreProductsMutation
} from 'store/services/productApi'
import Product from 'components/Product'

const ProductBin = NiceModal.create(({ category }) => {
  const modal = useModal()
  const { data: products, isLoading } = useGetProductsByCategoryQuery(
    { bin: true, category },
    { refetchOnMountOrArgChange: true }
  )
  const [restoreProducts, { isLoading: isLoadingRestore }] =
    useRestoreProductsMutation()
  const [selectedProducts, setSelectedProducts] = React.useState([])
  const isSelected = useCallback(
    (product) => selectedProducts.some((p) => p === product.id),
    [selectedProducts]
  )

  const handleResolve = () => {
    modal.resolve()
    modal.hide()
  }

  const onMultiSelect = (productId) => {
    const isSelect = isSelected({ id: productId })
    if (isSelect) {
      setSelectedProducts(selectedProducts.filter((p) => p !== productId))
    } else {
      setSelectedProducts([...selectedProducts, productId])
    }
  }

  const onRestoreProducts = async () => {
    await restoreProducts({ products: selectedProducts })
    handleResolve()
  }

  return (
    <>
      <Modal
        {...antdModal(modal)}
        closable={false}
        visible={modal.visible}
        onCancel={modal.hide}
        afterClose={modal.remove}
        okText='Rescatar producto'
        okButtonProps={{ disabled: !products?.length }}
        title={`Recuperar productos`}
        centered
        width={800}
        cancelText='Cancelar'
        onOk={onRestoreProducts}
        confirmLoading={isLoading || isLoadingRestore}
      >
        {isLoading ? (
          <Row justify='center' align='middle' style={{ height: 150 }}>
            <Spin />
          </Row>
        ) : (
          <Row>
            {products?.length ? (
              products.map((item, index) => {
                const selected = isSelected(item)
                return (
                  <Col xxl={12} xl={12} lg={12} md={12} key={index}>
                    <Product
                      id={item.id}
                      title={item.name}
                      description={item.description}
                      image={item.image}
                      price={item.price}
                      available={item.available}
                      visible={item.visible}
                      showOptions={false}
                      multiSelect
                      onMultiSelect={onMultiSelect}
                      selected={selected}
                    />
                  </Col>
                )
              })
            ) : (
              <>No existen productos eliminados en esta catergoría</>
            )}
          </Row>
        )}
      </Modal>
    </>
  )
})

ProductBin.propTypes = {
  category: PropTypes.number
}

export default ProductBin
