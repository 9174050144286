import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'config/constants';

export const colorsApi = createApi({
  reducerPath: 'colorsApi',
  baseQuery,
  endpoints: (builder) => ({
    getColors: builder.query({
      query: () => `/colors`,
    }),
    createColor: builder.mutation({
      query: (body) => ({
        url: `/colors`,
        method: 'POST',
        body,
      }),
    }),
    updateColor: builder.mutation({
      query: ({ id, values }) => ({
        url: `/colors/${id}`,
        method: 'PATCH',
        body: values,
      }),
    }),
    deleteColor: builder.mutation({
      query: (id) => ({
        url: `/colors/${id}`,
        method: 'DELETE',
      }),
    }),
    sortColors: builder.mutation({
      query: (colors) => ({
        url: `/colors/sort`,
        method: 'POST',
        body: {
          colors,
        },
      }),
    }),
  }),
});

export const {
  useGetColorsQuery,
  useCreateColorMutation,
  useUpdateColorMutation,
  useDeleteColorMutation,
  useSortColorsMutation,
} = colorsApi;
