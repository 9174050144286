import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Input, Modal, Form } from 'antd'
import './styles.less'
import {
  useCreateColorMutation,
  useGetColorsQuery,
  useUpdateColorMutation
} from 'store/services/colorsApi'
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react'

const ColorAddOrEdit = NiceModal.create(({ color }) => {
  const [form] = Form.useForm()
  const modal = useModal()
  const [createColor, { isLoading: creating }] = useCreateColorMutation()
  const [updateColor, { isLoading: updating }] = useUpdateColorMutation()
  const { isLoading } = useGetColorsQuery()

  const handleResolve = () => {
    modal.resolve()
    modal.hide()
  }

  const handleFinish = async (values) => {
    const data = {
      ...values
    }

    if (color) {
      await updateColor({ id: color.id, values: data }).then(handleResolve)
    } else {
      await createColor(data).then(handleResolve)
    }
  }

  useEffect(() => {
    if (!modal.visible) return
    console.log('vfsdf', color)
    color && form.setFieldsValue(color)
  }, [color, modal.visible, form])

  return (
    <>
      <Modal
        {...antdModal(modal)}
        title={color ? 'Nueva color' : 'Editar color'}
        visible={modal.visible}
        onCancel={modal.hide}
        afterClose={modal.remove}
        onOk={() => form.submit()}
        getContainer={false}
        okButtonProps={{ loading: creating || updating }}
        okText='Guardar'
      >
        {!isLoading && (
          <Form
            form={form}
            layout='vertical'
            onFinish={(values) => handleFinish(values)}
            initialValues={{
              name: ''
            }}
          >
            <Form.Item
              label='Nombre'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'El nombre del color es obligatorio'
                }
              ]}
            >
              <Input placeholder='Ej: Rojo' />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  )
})

ColorAddOrEdit.propTypes = {
  color: PropTypes.object
}

export default ColorAddOrEdit
