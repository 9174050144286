import Login from 'pages/auth/Login';
import Activate from 'pages/auth/Activate';

const authRoutes = [
  {
    title: 'Login',
    Component: Login,
    path: '/auth/login',
  },
  {
    title: 'Activate',
    Component: Activate,
    path: '/auth/activate',
  },
];

export default authRoutes;
