import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from 'components/Grid';
import { Button, Checkbox, Popover, Row, Input } from 'antd';
import _ from 'lodash';
import useFuse from 'hooks/useFuse';
import './styles.less';
import ScrollView from 'components/ScrollView';
import { DownOutlined } from '@ant-design/icons';
import { useEffect } from 'react';

const { Search } = Input;

const MultiCheckboxPopup = ({
  filterOptions,
  checkedList,
  onChange,
  buttonText,
  search,
  style,
}) => {
  const [shiftElement, setShiftElement] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const { results, setSearch } = useFuse(filterOptions, {
    keys: ['label', 'searchString'],
    matchAllOnEmptyQuery: true,
    threshold: 0.1,
    findAllMatches: true,
  });
  const [indeterminate, setIndeterminate] = useState(true);
  const onChangeCheck = (e) => {
    const { value } = e.target;
    let list = [];
    if (checkedList) list = [...checkedList];
    const isShift = e.nativeEvent.shiftKey;
    setShiftElement(isShift ? value : null);
    if (shiftElement) {
      const [startIndex, endIndex] = [
        filterOptions.findIndex((x) => x.value === shiftElement),
        filterOptions.findIndex((x) => x.value === value),
      ].sort((a, b) => a - b);
      const selectedList = filterOptions
        .slice(startIndex, endIndex + 1)
        .map((x) => x.value);
      list.push(..._.uniq(selectedList));
      setShiftElement(null);
    } else {
      if (e.target.checked) {
        list.push(value);
      } else {
        list = list.filter((val) => val !== value);
      }
    }
    onChange(list);
  };

  const onCheckAllChange = (e) => {
    onChange(e.target.checked ? filterOptions.map((x) => x.value) : []);
    setShiftElement(null);
  };

  useEffect(() => {
    setCheckAll(checkedList?.length === filterOptions?.length);
    setIndeterminate(
      checkedList?.length > 0 && checkedList?.length < filterOptions?.length
    );
  }, [checkedList, filterOptions]);

  return (
    <Popover
      overlayClassName='multi-checkbox'
      title={
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Todos
        </Checkbox>
      }
      trigger='click'
      placement='right'
      content={
        <Grid width={250} gridTemplateColumns='1fr' gridGap={15}>
          {search && (
            <Row>
              <Search
                placeholder='Buscar'
                style={{ width: '100%' }}
                onChange={(e) => setSearch(e.target.value)}
                allowClear
              />
            </Row>
          )}
          <ScrollView style={{ maxHeight: 400, overflow: 'auto' }}>
            <Checkbox.Group value={checkedList}>
              <Grid gridGap={5}>
                {results.map((item, index) => {
                  const type = item.item ? item.item : item;
                  return (
                    <Grid justify='flex-start' key={index}>
                      <Checkbox
                        checked='false'
                        value={type.value}
                        onChange={onChangeCheck}
                      >
                        {type.label}
                      </Checkbox>
                    </Grid>
                  );
                })}
              </Grid>
            </Checkbox.Group>
          </ScrollView>
        </Grid>
      }
    >
      <Button className='multi-checkbox-btn' style={style} block>
        {`${buttonText} ${
          checkedList?.length ? ` (${checkedList?.length})` : ''
        }`}
        <DownOutlined />
      </Button>
    </Popover>
  );
};

MultiCheckboxPopup.propTypes = {
  buttonText: PropTypes.string,
  checkedList: PropTypes.array,
  onChange: PropTypes.func,
  filterOptions: PropTypes.array,
  search: PropTypes.bool,
  style: PropTypes.object,
};

export default MultiCheckboxPopup;
