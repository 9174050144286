import React from 'react';
import { ProfileOutlined } from '@ant-design/icons';
import Categories from 'pages/Categories';

const categoriesRoutes = {
  title: 'Categorías',
  icon: <ProfileOutlined />,
  path: '/categorias',
  sidebar: true,
  Component: Categories,
};

export default categoriesRoutes;
