import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Card } from 'antd';
// import { OrderedListOutlined } from '@ant-design/icons';
import './styles.less';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { List, arrayMove } from 'react-movable';
import { iconRender } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSortCategoriesMutation } from 'store/services/categoryApi';

const OrderCategories = NiceModal.create(({ catList }) => {
  const modal = useModal();
  const [items, setItems] = React.useState(
    catList?.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {})
  );
  const [sortCategories, { isLoading }] = useSortCategoriesMutation();
  const handleResolve = () => {
    modal.resolve();
    modal.hide();
  };
  console.log('catList', catList);
  const handleFinish = async () => {
    // get all subcategories
    const newItems = Object.keys(items).reduce((acc, itemKey) => {
      acc = [...acc, ...items[itemKey].subcategories];
      return acc;
    }, []);
    sortCategories(newItems.map((item) => item.id)).then(handleResolve);
  };
  console.log('items', items);
  return (
    <>
      <Modal
        {...antdModal(modal)}
        title='Ordenar Categorias'
        visible={modal.visible}
        onCancel={modal.hide}
        afterClose={modal.remove}
        onOk={handleFinish}
        getContainer={false}
        okButtonProps={{ loading: isLoading }}
        okText='Guardar'
      >
        {items &&
          Object.keys(items).map((itemKey) => (
            <Card style={{ marginBottom: 10 }}>
              <h3>{items[itemKey].name}</h3>
              <List
                key={itemKey}
                values={items[itemKey].subcategories}
                onChange={({ oldIndex, newIndex }) =>
                  setItems({
                    ...items,
                    [itemKey]: {
                      ...items[itemKey],
                      subcategories: arrayMove(
                        items[itemKey].subcategories,
                        oldIndex,
                        newIndex
                      ),
                    },
                  })
                }
                lockVertically
                renderList={({ children, props }) => (
                  <ul {...props}>{children}</ul>
                )}
                renderItem={({ value: category, props }) => (
                  <li
                    {...props}
                    style={{
                      ...props.style,
                      zIndex: 1000,
                      listStyle: 'none',
                      marginBottom: 10,
                    }}
                  >
                    <Card hoverable>
                      <Card.Meta
                        avatar={
                          <FontAwesomeIcon
                            icon={iconRender(category.icon)}
                            className='icon'
                            size='2x'
                          />
                        }
                        title={category.name}
                      />
                    </Card>
                  </li>
                )}
              />
            </Card>
          ))}
      </Modal>
    </>
  );
});

OrderCategories.propTypes = {
  catList: PropTypes.array,
};

export default OrderCategories;
