import React from 'react';
import { ToolOutlined } from '@ant-design/icons';
import Requisition from 'pages/Requisition';

const requisitionsRoutes = {
  title: 'Solicitudes',
  icon: <ToolOutlined />,
  path: '/solicitudes',
  sidebar: true,
  condition: ({ user }) => user.god,
  Component: Requisition,
};

export default requisitionsRoutes;
