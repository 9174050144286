import React from 'react';
import { Row, Spin } from 'antd';

export default function MainLoading() {
  return (
    <Row style={{ marginTop: '10%' }} justify='center' size='large'>
      <Spin />
    </Row>
  );
}
