import { useEffect } from 'react';
import { Row, Col } from 'antd';
import ActivateForm from 'components/Auth/ActivateForm';
import {
  useActivateAccountMutation,
  useVerifyActivateMutation,
} from 'store/services/authApi';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { ReactComponent as Logo } from 'assets/logo-white-color.svg';
import MainLoading from 'components/MainLoading';
import { isBoolean } from 'lodash';

const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const [verifyActivate, { data: verified, isLoading }] =
    useVerifyActivateMutation();
  const [activateAccount] = useActivateAccountMutation();
  const query = qs.parse(location.search);

  const onFinish = async ({ password }) => {
    await activateAccount({ token: query.t, password }).then(() => {
      history.push('/');
    });
  };

  useEffect(() => {
    (async () => {
      await verifyActivate({
        token: query.t,
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyActivate]);

  return (
    <Row justify='center' align='middle' style={{ flexDirection: 'column' }}>
      <Col>
        <Logo width={250} />
      </Col>
      {verified ? (
        <>
          <Col>
            <h3 style={{ color: 'white' }}>
              Escribe una contraseña para tu nueva cuenta
            </h3>
          </Col>
          <Col>
            <ActivateForm onFinish={onFinish} />
          </Col>
        </>
      ) : isLoading || !isBoolean(verified) ? (
        <MainLoading />
      ) : (
        <h1 style={{ color: 'white' }}>
          El enlace para activar tu cuenta ha expirado
        </h1>
      )}
    </Row>
  );
};

export default Login;
