import React, { useMemo, useState } from 'react'
import {
  Row,
  Col,
  Button,
  Tooltip,
  Dropdown,
  Menu,
  Input,
  Table,
  Typography,
  Image
} from 'antd'
import MainLoading from 'components/MainLoading'
import Result from 'components/Result'
import {
  OrderedListOutlined,
  PlusOutlined,
  MoreOutlined
} from '@ant-design/icons'
import {
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation
} from 'store/services/categoryApi'
import {
  useDeleteProductMutation,
  useUpdateProductMutation
} from 'store/services/productApi'
import { useModal } from '@ebay/nice-modal-react'
import { MODAL, MODELS } from 'config/constants'
import { isDesktop, isMobile } from 'react-device-detect'
import useFuse from 'hooks/useFuse'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

const { Search } = Input

const Categories = () => {
  const [isSearching, setIsSearching] = useState(false)
  const { data: categories, isLoading, refetch } = useGetCategoriesQuery()
  const [deleteProduct] = useDeleteProductMutation()
  const [deleteCategory] = useDeleteCategoryMutation()
  const [updateProduct] = useUpdateProductMutation()
  const [updateCategory] = useUpdateCategoryMutation()
  const actionModal = useModal(MODAL.ActionConfirm)
  const categoryModal = useModal(MODAL.CategoryAddOrEdit)
  const subCategoryModal = useModal(MODAL.SubCategoryAddOrEdit)

  const handleActionModal = (item, type, action) => {
    actionModal
      .show({
        item,
        type,
        action,
        onConfirm: async () => {
          const formData = new FormData()
          if (type === MODELS.product) {
            switch (action) {
              case 'AVAILABLE': {
                formData.set('available', !item.available)
                await updateProduct({
                  id: item.id,
                  formData
                })
                break
              }
              case 'VISIBLE': {
                formData.set('visible', !item.visible)
                await updateProduct({
                  id: item.id,
                  formData
                })
                break
              }
              case 'DELETE': {
                await deleteProduct(item.id)
                break
              }
              default:
            }
          } else if (MODELS.category) {
            switch (action) {
              case 'AVAILABLE': {
                await updateCategory({
                  id: item.id,
                  values: { available: !item.available }
                })
                break
              }
              case 'VISIBLE': {
                await updateCategory({
                  id: item.id,
                  values: { visible: !item.visible }
                })
                break
              }
              case 'DELETE': {
                await deleteCategory(item.id)
                break
              }
              default:
            }
          }
        }
      })
      .then(refetch)
  }

  const columns = [
    {
      title: 'Nombre',
      render: (row) => {
        const productRef = row.item ? row.item.productRef : row.productRef
        const image = productRef?.images?.[0]
        return (
          <>
            {image && <Image width={28} height={28} src={image} />}
            <span style={{ marginLeft: image ? 8 : 0 }}>
              {row.item ? row.item.name : row.name}
            </span>
          </>
        )
      }
    },
    {
      title: 'ID',
      width: '90px',
      render: (row) => (row.item ? row.item.id : row.id)
    },
    {
      title: 'Tipo',
      render: (row) => {
        const hasParent = row.item ? row.item.parentId : row.parentId
        return hasParent ? 'Subcategoría' : 'Categoría'
      }
    },
    {
      title: 'Productos',
      render: (row) => (row.item ? row.item.productsCount : row.productsCount)
    },
    {
      title: 'Disponible',
      render: (row) => {
        const available = row.item ? row.item.available : row.available
        return (
          <>
            {available ? (
              <FontAwesomeIcon icon={faCheck} style={{ color: '#52c41a' }} />
            ) : (
              <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
            )}
          </>
        )
      }
    },
    {
      title: 'Visible',
      render: (row) => {
        const visible = row.item ? row.item.visible : row.visible
        return (
          <>
            {visible ? (
              <FontAwesomeIcon icon={faCheck} style={{ color: '#52c41a' }} />
            ) : (
              <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />
            )}
          </>
        )
      }
    },
    {
      width: '40px',
      render: (row) => {
        const category = row.item ? row.item : row
        return (
          <Dropdown
            trigger={['click']}
            placement='bottomRight'
            overlay={
              <Menu style={{ width: 200 }}>
                <Menu.Item
                  key='available'
                  onClick={() =>
                    handleActionModal(category, MODELS.category, 'AVAILABLE')
                  }
                >
                  {category.available
                    ? 'Marcar como no disponible'
                    : 'Marcar como disponible'}
                </Menu.Item>
                <Menu.Item
                  key='visible'
                  onClick={() =>
                    handleActionModal(category, MODELS.category, 'VISIBLE')
                  }
                >
                  {category.visible ? 'Ocultar' : 'Mostrar'}
                </Menu.Item>
                <Menu.Item
                  key='edit'
                  onClick={() => {
                    if (!category.parentId) {
                      categoryModal.show({ category }).then(refetch)
                    } else {
                      subCategoryModal.show({ category }).then(refetch)
                    }
                  }}
                >
                  Editar
                </Menu.Item>
                <Menu.Item
                  key='delete'
                  danger
                  onClick={() =>
                    handleActionModal(
                      category,
                      MODELS.category,
                      category.children?.length ? 'FORBIDDEN' : 'DELETE'
                    )
                  }
                >
                  Eliminar
                </Menu.Item>
              </Menu>
            }
          >
            <Button type='text' icon={<MoreOutlined />} />
          </Dropdown>
        )
      }
    }
  ]

  const nestedCategories = useMemo(() => {
    const list = categories
      ?.filter((item) => !item.parentId)
      .map((category) => {
        const children = categories
          .filter((el) => el.parentId === category.id)
          .map((cat) => ({
            ...cat,
            productsCount: cat.products.length
          }))
        return {
          ...category,
          children: children.length ? children : null,
          products: _.flatten(children.map(({ products }) => products)),
          productsCount: _.sumBy(children, ({ products }) => _.size(products))
        }
      })
    return _.orderBy(list, ['name'], ['asc'])
  }, [categories])

  const separatedCategories = useMemo(() => {
    const list = categories?.map((cat) => {
      const children = categories
        .filter((el) => el.parentId === cat.id)
        .map((el) => ({ ...el, productsCount: el.products.length }))
      return {
        ...cat,
        productsCount: children.length
          ? _.sumBy(children, ({ products }) => _.size(products))
          : cat.products.length
      }
    })
    return list
  }, [categories])

  const { results, setSearch } = useFuse(separatedCategories, {
    keys: ['name'],
    matchAllOnEmptyQuery: true,
    threshold: 0.2,
    findAllMatches: true,
    includeMatches: true
  })

  if (isLoading) return <MainLoading />

  return (
    <>
      <Row gutter={isDesktop ? [20, 20] : [0, 0]}>
        <Col span={24}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Listado de categorías
          </Typography.Title>
        </Col>
        <Col span={24}>
          {categories?.length > 0 ? (
            <div
              className={`searchbar-container ${
                isMobile ? 'mobile' : undefined
              }`}
            >
              <Search
                placeholder='Buscar productos...'
                style={{ width: isMobile ? '100%' : 300 }}
                onChange={(e) => {
                  setIsSearching(!!e.target.value)
                  setSearch(e.target.value)
                }}
                allowClear
              />
              <Tooltip
                color='primary'
                visible={categories?.length < 1}
                placement={isMobile ? 'bottomLeft' : 'right'}
                title='Aún no has agregado productos a tu catálogo, comencemos presionando el
                    botón Nueva categoría'
              >
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key='category'
                        icon={<OrderedListOutlined />}
                        onClick={() => categoryModal.show().then(refetch)}
                      >
                        Categoría
                      </Menu.Item>
                      <Menu.Item
                        key='subcategory'
                        icon={<OrderedListOutlined />}
                        onClick={() => subCategoryModal.show().then(refetch)}
                      >
                        Subcategoría
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={['click']}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Button type='primary' icon={<PlusOutlined />}>
                    {!isMobile ? 'Nuevo' : undefined}
                  </Button>
                </Dropdown>
              </Tooltip>

              {/* <Tooltip title='Ordenar Categorías'>
                <Button
                  type='primary'
                  icon={<UnorderedListOutlined />}
                  onClick={() => {
                    orderCategoriesModal
                      .show({
                        categories
                      })
                      .then(refetch)
                  }}
                />
              </Tooltip> */}
            </div>
          ) : (
            <Col span={24}>
              <Result
                title='Bienvenido'
                description='Aún no has agregado productos a tu catálogo, comencemos creando una categoría'
                onClick={() => categoryModal.show().then(refetch)}
                buttonText='Crear categoría'
              />
            </Col>
          )}
        </Col>

        <Col span={24}>
          <Table
            dataSource={isSearching ? results : nestedCategories}
            columns={columns}
            rowKey={(row) => (row.item ? row.item.id : row.id)}
          />
        </Col>
      </Row>
    </>
  )
}

export default Categories
