import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Button, Modal, Tag, Checkbox } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { EllipsisOutlined } from '@ant-design/icons';
import { iconRender } from 'utils'
import cn from 'classnames'
import './styles.less'
import { isDesktop, isMobile } from 'react-device-detect'
import { StarFilled } from '@ant-design/icons'

const Product = ({
  id,
  title,
  sku,
  images,
  price,
  available,
  visible,
  icon,
  onEdit,
  onDelete,
  onSetAvailable,
  onSetVisible,
  multiSelect = false,
  selected,
  featured,
  onSetFeatured
}) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const { Title, Text } = Typography

  return (
    <>
      <div
        className={cn('categoryItemContainer', { multiSelect, selected })}
        onClick={() => setVisibleModal(true)}
      >
        <div className='product-card'>
          <>
            <StarFilled style={{ color: featured ? 'orange' : '#cccccc' }} />
          </>
          <div className='image'>
            {images ? (
              <img
                alt={title}
                src={images}
                style={{
                  opacity: (!available || !visible) && 0.4
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={iconRender(icon)}
                className='icon'
                size={isMobile ? '1x' : '2x'}
              />
            )}
            {!available && isDesktop && (
              <div className='not-available'>No disponible</div>
            )}
          </div>
          <div className='data-container'>
            <Text type={'secondary'}>SKU: {sku}</Text>

            <div className='titleContainer'>
              <Text
                strong
                className='title'
                type={(!available || !visible) && 'secondary'}
              >
                {title || 'Title'}
              </Text>
            </div>

            {(!available || !visible) && (
              <div style={{ marginTop: 10 }}>
                {!available && <Tag color='red'>No disponible</Tag>}
                {!visible && <Tag>Oculto</Tag>}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={visibleModal}
        closable={false}
        onCancel={() => setVisibleModal(false)}
        footer={false}
        centered
      >
        <div className='buttons-modal'>
          <Button
            type='primary'
            danger={available}
            block
            onClick={() => {
              setVisibleModal(false)
              onSetAvailable()
            }}
          >
            {available ? 'Marcar como no disponible' : 'Marcar como disponible'}
          </Button>
          <Button
            block
            type='text'
            onClick={() => {
              setVisibleModal(false)
              onSetVisible()
            }}
          >
            {visible ? 'Ocultar' : 'Mostrar'}
          </Button>
          <Button
            block
            type='text'
            onClick={() => {
              setVisibleModal(false)
              onSetFeatured()
            }}
          >
            {visible ? 'Destacar' : 'No destacar'}
          </Button>
          <Button
            type='text'
            block
            onClick={() => {
              setVisibleModal(false)
              onEdit()
            }}
          >
            Editar
          </Button>
          <Button
            type='text'
            danger
            block
            onClick={() => {
              setVisibleModal(false)
              onDelete()
            }}
          >
            Eliminar
          </Button>
        </div>
      </Modal>
    </>
  )
}

Product.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.number,
  available: PropTypes.bool,
  visible: PropTypes.bool,
  icon: PropTypes.string,
  onEdit: PropTypes.func,
  onSetAvailable: PropTypes.func,
  onSetVisible: PropTypes.func,
  onDelete: PropTypes.func,
  showOptions: PropTypes.bool,
  multiSelect: PropTypes.bool,
  onMultiSelect: PropTypes.func,
  featured: PropTypes.bool,
  onSetFeatured: PropTypes.func
}

export default Product
