import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'config/constants';
import { authApi } from './authApi';

export const restobarApi = createApi({
  reducerPath: 'restobarApi',
  baseQuery,
  endpoints: (builder) => ({
    updateRestobar: builder.mutation({
      query: ({ id, formData }) => ({
        url: `/restobars/${id}`,
        method: 'PATCH',
        headers: {
          Accept: 'multipart/form-data',
        },
        body: formData,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            authApi.util.updateQueryData('user', undefined, (draft) => {
              Object.assign(draft.restobar, data.data);
            })
          );
        } catch {}
      },
    }),
  }),
});

export const { useUpdateRestobarMutation } = restobarApi;
