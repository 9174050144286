import { createApi } from '@reduxjs/toolkit/query/react';
import { message } from 'antd';
import { baseQuery } from 'config/constants';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery,
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: () => `/settings`,
    }),
    createSettings: builder.mutation({
      query: (body) => ({
        url: `/settings`,
        method: 'POST',
        body,
      }),
    }),
    updateSettings: builder.mutation({
      query: ({ key, value }) => ({
        url: `/settings/${key}`,
        method: 'PATCH',
        body: {
          key,
          value,
        },
      }),
      transformResponse: (response) => {
        if (response.message) {
          message.success(response.message);
        }
      },
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useCreateSettingsMutation,
  useUpdateSettingsMutation,
} = settingsApi;
