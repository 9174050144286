import React, { useMemo, useState } from 'react';
import { Layout, Alert, Breadcrumb, Tag } from 'antd';
import { useLocation, Switch, Link } from 'react-router-dom';
import NavBar from './NavBar';
import Sidebar from './SideBar';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Offline } from 'react-detect-offline';
import { HomeOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import MainLoading from 'components/MainLoading';
import './styles/default.less';
import { isNumeric } from 'utils';
import { useAuth } from 'hooks/useAuth';
import { useUserQuery } from 'store/services/authApi';
import { isMobile } from 'react-device-detect';

const { Content, Header } = Layout;

function DefaultLayout({ children: childRoutes }) {
  const { logout } = useAuth();
  const { data: user, isLoading } = useUserQuery();

  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const onCollapseLeftSide = () => {
    setCollapsed(!collapsed);
  };
  const classnames = cx('default-layout', 'full-layout', {
    fixed: true,
    'fixed-breadcrumbs': true,
    'hided-breadcrumbs': false,
  });

  const breadcrumbs = useMemo(() => {
    const { pathname } = location;
    if (pathname === '/') return ['/'];
    let splitted = pathname.split('/');
    return [
      '/',
      ...splitted
        .filter((x) => !_.isEmpty(x) && !isNumeric(x))
        .map((x) => _.upperFirst(x.replaceAll(/-/gi, ' '))),
    ];
  }, [location]);

  const breadcrumbsDict = useMemo(() => {
    return breadcrumbs.reduce(
      (acc, breadcrumb, i) => ({
        ...acc,
        [i]:
          i === 0
            ? breadcrumb
            : breadcrumbs
                .slice(0, i + 1)
                .join('/')
                .substring(1)
                .toLowerCase(),
      }),
      {}
    );
  }, [breadcrumbs]);

  if (isLoading) return <MainLoading />;

  return (
    <Layout className={classnames}>
      <Offline polling={{ interval: 20000, url: 'https://icanhazip.com/' }}>
        <Alert
          message='Usted se encuentra sin conexion a internet.'
          type='error'
          showIcon
        />
      </Offline>
      <Header>
        <NavBar
          collapsed={false}
          onCollapseLeftSide={onCollapseLeftSide}
          onExpandTopBar={true}
          toggleSidebarHeader={true}
          user={user}
          logout={logout}
          isMobile={isMobile}
        />
      </Header>
      <Layout>
        <Sidebar
          collapsed={collapsed}
          onCollapse={onCollapseLeftSide}
          isMobile={isMobile}
        />
        <Content>
          <Layout className='full-layout'>
            <div className='header-breadcrumbs'>
              <Breadcrumb>
                {breadcrumbs.map((item, i) => (
                  <Breadcrumb.Item key={i}>
                    <Link
                      to={
                        breadcrumbs.length === i + 1
                          ? '#'
                          : breadcrumbsDict[i].replace(/[\s\t]/gi, '-')
                      }
                    >
                      {item === '/' ? (
                        <Tag>
                          <HomeOutlined color='primary' />
                        </Tag>
                      ) : (
                        <Tag>{item}</Tag>
                      )}
                    </Link>
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </div>

            <Content style={{ overflow: 'hidden' }}>
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  classNames='fade'
                  timeout={200}
                >
                  <Layout className='full-layout'>
                    <Content className='router-page'>
                      <Switch location={location}>{childRoutes}</Switch>
                    </Content>
                  </Layout>
                </CSSTransition>
              </SwitchTransition>
            </Content>
          </Layout>
        </Content>
      </Layout>
    </Layout>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.array,
};

export default DefaultLayout;
