import React, { useMemo } from 'react'
import RenderIfVisible from 'react-render-if-visible'
import {
  Row,
  Col,
  Button,
  Tooltip,
  Dropdown,
  Menu,
  Input,
  Card,
  Typography,
  Space
} from 'antd'
import Category from 'components/Category'
import MainLoading from 'components/MainLoading'
import Result from 'components/Result'
import {
  MenuOutlined,
  OrderedListOutlined,
  PlusOutlined,
  MoreOutlined
} from '@ant-design/icons'
import {
  useDeleteCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation
} from 'store/services/categoryApi'
import {
  useDeleteProductMutation,
  useUpdateProductMutation
} from 'store/services/productApi'
import { useModal } from '@ebay/nice-modal-react'
import { MODAL, MODELS } from 'config/constants'
import { isDesktop, isMobile } from 'react-device-detect'
import useFuse from 'hooks/useFuse'
import Section from 'components/Common/Section'
import _ from 'lodash'

const { Search } = Input

const Home = () => {
  const { data: categories, isLoading, refetch } = useGetCategoriesQuery()
  const [deleteProduct] = useDeleteProductMutation()
  const [deleteCategory] = useDeleteCategoryMutation()
  const [updateProduct] = useUpdateProductMutation()
  const [updateCategory] = useUpdateCategoryMutation()
  const actionModal = useModal(MODAL.ActionConfirm)
  const categoryModal = useModal(MODAL.CategoryAddOrEdit)
  const subCategoryModal = useModal(MODAL.SubCategoryAddOrEdit)
  const itemModal = useModal(MODAL.ItemAddOrEdit)

  const handleActionModal = (item, type, action) => {
    actionModal
      .show({
        item,
        type,
        action,
        onConfirm: async () => {
          const formData = new FormData()
          if (type === MODELS.product) {
            switch (action) {
              case 'FEATURED': {
                formData.set('featured', !item.featured)
                await updateProduct({
                  id: item.id,
                  formData
                })
                break
              }
              case 'AVAILABLE': {
                formData.set('available', !item.available)
                await updateProduct({
                  id: item.id,
                  formData
                })
                break
              }
              case 'VISIBLE': {
                formData.set('visible', !item.visible)
                await updateProduct({
                  id: item.id,
                  formData
                })
                break
              }
              case 'DELETE': {
                await deleteProduct(item.id)
                break
              }
              default:
            }
          } else if (MODELS.category) {
            switch (action) {
              case 'AVAILABLE': {
                await updateCategory({
                  id: item.id,
                  values: { available: !item.available }
                })
                break
              }
              case 'VISIBLE': {
                await updateCategory({
                  id: item.id,
                  values: { visible: !item.visible }
                })
                break
              }
              case 'DELETE': {
                await deleteCategory(item.id)
                break
              }
              default:
            }
          }
          refetch()
        }
      })
      .then(() => refetch())
  }

  const catList = useMemo(() => {
    let parentCategories = categories
      ?.filter((category) => !category.parentId)
      .map((category) => ({
        ...category,
        subcategories: [],
        onSetAvailable: () =>
          handleActionModal(category, MODELS.category, 'AVAILABLE'),
        onSetVisible: () =>
          handleActionModal(category, MODELS.category, 'VISIBLE'),
        onDelete: () => handleActionModal(category, MODELS.category, 'DELETE')
      }))

    categories?.forEach((category) => {
      const productsList = category.products.map((product) => ({
        ...product,
        onEdit: () =>
          itemModal
            .show({
              categories,
              category,
              item: product
            })
            .then(refetch),
        onDelete: () => handleActionModal(product, MODELS.product, 'DELETE'),
        onSetAvailable: () =>
          handleActionModal(product, MODELS.product, 'AVAILABLE'),
        onSetVisible: () =>
          handleActionModal(product, MODELS.product, 'VISIBLE'),
        onSetFeatured: () =>
          handleActionModal(product, MODELS.product, 'FEATURED')
      }))
      const parentCatIndex = parentCategories.findIndex(
        (parent) => parent.id === category.parentId
      )
      if (category.parentId) {
        if (parentCatIndex !== null) {
          let prevSubs = parentCategories[parentCatIndex].subcategories
          parentCategories[parentCatIndex].subcategories = [
            ...prevSubs,
            {
              ...category,
              products: _.orderBy(productsList, ['name'], ['asc'])
            }
          ]
        }
      } else {
        const parentIndex = parentCategories.findIndex(
          (parent) => parent.id === category.id
        )
        if (parentIndex !== null) {
          const parentProductList = _.flatten(
            parentCategories[parentIndex].subcategories.map(
              (sub) => sub.products
            )
          )
          parentCategories[parentIndex].products = parentProductList
          parentCategories[parentIndex].onEdit = () =>
            categoryModal
              .show({ category: parentCategories[parentIndex] })
              .then(refetch)
        }
      }
    })
    return _.orderBy(parentCategories, ['order'], ['asc'])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories])

  const { results, setSearch } = useFuse(catList, {
    keys: ['subcategories.products.sku', 'subcategories.products.name'],
    matchAllOnEmptyQuery: true,
    threshold: 0.2,
    findAllMatches: true,
    includeMatches: true,
    ignoreLocation: true,
    isCaseSensitive: false
  })

  const getFilteredResults = (results) => {
    return results
      .map((result) => {
        const item = _.cloneDeep(result.item || result)
        item.subcategories = item.subcategories
          .map((subcategory) => {
            if (!result.matches) return subcategory
            subcategory.products = subcategory.products.filter((product) =>
              result.matches.some(
                (match) =>
                  (match.key === 'subcategories.products.name' &&
                    match.value === product.name) ||
                  (match.key === 'subcategories.products.sku' &&
                    match.value === product.sku)
              )
            )
            return subcategory
          })
          .filter((subcategory) => subcategory.products.length > 0)
        return item
      })
      .filter((result) => result.subcategories.length > 0)
  }

  const searchResults = useMemo(() => {
    return getFilteredResults(results)
  }, [results])

  if (isLoading) return <MainLoading />

  return (
    <>
      <Row gutter={isDesktop ? [20, 20] : [0, 0]}>
        <Col span={24}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            Productos
          </Typography.Title>
        </Col>
        <Col span={24}>
          {categories?.length > 0 ? (
            <div
              className={`searchbar-container ${
                isMobile ? 'mobile' : undefined
              }`}
            >
              <Search
                placeholder='Buscar productos...'
                style={{ width: isMobile ? '100%' : 300 }}
                onChange={(e) => setSearch(e.target.value)}
                allowClear
              />
              <Tooltip
                color='primary'
                visible={categories?.length < 1}
                placement={isMobile ? 'bottomLeft' : 'right'}
                title='Aún no has agregado productos a tu catálogo, comencemos presionando el
                    botón Nueva categoría'
              >
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key='category'
                        icon={<OrderedListOutlined />}
                        onClick={() => categoryModal.show().then(refetch)}
                      >
                        Categoría
                      </Menu.Item>

                      <Menu.Item
                        key='subcategory'
                        icon={<OrderedListOutlined />}
                        onClick={() => subCategoryModal.show().then(refetch)}
                      >
                        Subcategoría
                      </Menu.Item>

                      {categories.length > 0 && (
                        <Menu.Item
                          key='product'
                          icon={<MenuOutlined />}
                          onClick={() => {
                            itemModal
                              .show({
                                categories
                              })
                              .then(refetch)
                          }}
                        >
                          Producto
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                  trigger={['click']}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  <Button type='primary' icon={<PlusOutlined />}>
                    {!isMobile ? 'Nuevo' : undefined}
                  </Button>
                </Dropdown>
              </Tooltip>

              {/* <Tooltip title='Ordenar Categorías'>
                <Button
                  type='primary'
                  icon={<UnorderedListOutlined />}
                  onClick={() => {
                    orderCategoriesModal
                      .show({
                        categories,
                        catList
                      })
                      .then(refetch)
                  }}
                />
              </Tooltip> */}
            </div>
          ) : (
            <Col span={24}>
              <Result
                title='Bienvenido'
                description='Aún no has agregado productos a tu catálogo, comencemos creando una categoría'
                onClick={() => categoryModal.show().then(refetch)}
                buttonText='Crear categoría'
              />
            </Col>
          )}
        </Col>
        {searchResults?.map((item, index) => {
          const parent = item.item || item
          return (
            <Col span={24} key={index}>
              <RenderIfVisible visibleOffset={3000} defaultHeight={1500}>
                <Section
                  title={parent.name}
                  extra={
                    <Space size='small'>
                      <Button
                        type='primary'
                        onClick={() => {
                          const parentId = parent.id
                          return subCategoryModal
                            .show({ _, parentId })
                            .then(refetch)
                        }}
                      >
                        <Space>
                          <PlusOutlined /> Subcategoría
                        </Space>
                      </Button>
                      <Dropdown
                        trigger={['click']}
                        placement='bottomRight'
                        overlay={
                          <Menu>
                            <Menu.Item
                              danger={parent.visible}
                              onClick={parent.onSetAvailable}
                            >
                              {parent.available
                                ? 'Mostrar como no disponible'
                                : 'Mostrar como disponible'}
                            </Menu.Item>
                            <Menu.Item onClick={parent.onSetVisible}>
                              {parent.visible ? 'Ocultar' : 'Mostrar'}
                            </Menu.Item>
                            <Menu.Item onClick={parent.onEdit}>
                              Editar
                            </Menu.Item>
                            <Menu.Item
                              danger
                              onClick={() =>
                                handleActionModal(
                                  parent,
                                  MODELS.category,
                                  parent?.subcategories?.length
                                    ? 'FORBIDDEN'
                                    : 'DELETE'
                                )
                              }
                            >
                              Eliminar
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <Button icon={<MoreOutlined />} />
                      </Dropdown>
                    </Space>
                  }
                >
                  <Row gutter={[20, 20]}>
                    {parent.subcategories.length ? (
                      parent.subcategories?.map((category, index) => (
                        <Col span={24} key={index}>
                          <Category
                            id={category.id}
                            name={category.name}
                            icon={category.icon}
                            visible={category.visible}
                            available={category.available}
                            onSetAvailable={() =>
                              handleActionModal(
                                category,
                                MODELS.category,
                                'AVAILABLE'
                              )
                            }
                            onSetVisible={() =>
                              handleActionModal(
                                category,
                                MODELS.category,
                                'VISIBLE'
                              )
                            }
                            onDelete={() =>
                              handleActionModal(
                                category,
                                MODELS.category,
                                'DELETE'
                              )
                            }
                            onEdit={() =>
                              subCategoryModal.show({ category }).then(refetch)
                            }
                            onClickNewItem={() =>
                              itemModal
                                .show({
                                  categories,
                                  category,
                                  categoryLocked: true
                                })
                                .then(refetch)
                            }
                            products={category.products}
                            onRefresh={refetch}
                          />
                        </Col>
                      ))
                    ) : (
                      <Col span={24}>
                        <Card>
                          <Row gutter={[20, 20]} justify='space-between'>
                            <Col span={24}>
                              Esta categoría se encuentra vacía, agrega una
                              subcategoría.
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    )}
                  </Row>
                </Section>
              </RenderIfVisible>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default Home
