import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'config/constants';

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery,
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => `/categories`,
    }),
    createCategory: builder.mutation({
      query: (body) => ({
        url: `/categories`,
        method: 'POST',
        body,
      }),
    }),
    updateCategory: builder.mutation({
      query: ({ id, values }) => ({
        url: `/categories/${id}`,
        method: 'PATCH',
        body: values,
      }),
    }),
    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/categories/${id}`,
        method: 'DELETE',
      }),
    }),
    sortCategories: builder.mutation({
      query: (categories) => ({
        url: `/categories/sort`,
        method: 'POST',
        body: {
          categories,
        },
      }),
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useSortCategoriesMutation,
} = categoryApi;
