import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Switch } from 'react-router-dom';
import './styles/auth.less';
const { Content } = Layout;

function AuthLayout({ children }) {
  return (
    <div className='auth-layout'>
      <div className='background' />
      <Layout className='full-layout user-layout fixed'>
        <Content className=''>
          <Switch>{children}</Switch>
        </Content>
      </Layout>
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.array,
};

export default AuthLayout;
