import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Row } from 'antd';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import QRCode from 'react-qr-code';

const QRDownload = NiceModal.create(({ link }) => {
  const modal = useModal();
  const onDownload = () => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };
  return (
    <>
      <Modal
        {...antdModal(modal)}
        closable={false}
        visible={modal.visible}
        onCancel={modal.hide}
        afterClose={modal.remove}
        onOk={onDownload}
        okText='Descargar'
        title=''
        centered
        cancelText='Cancelar'
      >
        <Row justify='center'>
          <QRCode id='QRCode' value={link} download />
        </Row>
      </Modal>
    </>
  );
});

QRDownload.propTypes = {
  link: PropTypes.object,
};

export default QRDownload;
