import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { MenuOutlined } from '@ant-design/icons';
import { Menu, Layout, Drawer } from 'antd';
import appRoutes from 'routes/routes';
import PropTypes from 'prop-types';
import { filterRoutes } from 'utils';
import _ from 'lodash';
import './index.less';
import { useAuth } from 'hooks/useAuth';
import logo from 'assets/logo.svg';

const { Sider } = Layout;
const SubMenu = Menu.SubMenu;

function Sidebar({ collapsed, onCollapse, isMobile }) {
  const [routes, setRoutes] = useState([]);
  const location = useLocation();
  const { user } = useAuth();
  useEffect(() => {
    setRoutes(
      filterRoutes(
        _.cloneDeep(appRoutes)
          .filter((r) => !!r.sidebar)
          .map((r) => {
            if (!r.subRoutes) return r;
            r.subRoutes = r.subRoutes.filter((s) => !!s.sidebar);
            return r;
          }),
        { user }
      )
    );
  }, [user]);

  const classnames = cx('sidebar-left', 'sidebar-default', {
    affix: true,
    light: true, //change theme dark or light
    'sidebar-left-sm': collapsed,
    'sidebar-left-close': !collapsed,
  });
  const leftCollapsedWidth = collapsed ? 60 : 0;

  const getNavMenuItems = useCallback(
    (menusData) => {
      if (!menusData) {
        return [];
      }
      return menusData
        .filter((item) => item.title)
        .map((item, i) => getSubMenuOrItem(item, i))
        .filter((item) => item);
    },
    // eslint-disable-next-line
    [location.pathname]
  );

  const getSubMenuOrItem = (item, i) => {
    if (item.subRoutes && item.subRoutes.some((child) => child.title)) {
      const childrenItems = getNavMenuItems(item.subRoutes);
      if (childrenItems && childrenItems.length > 0) {
        return (
          <SubMenu
            title={
              item.icon ? (
                <span>
                  {item.icon}
                  <span>{item.title}</span>
                </span>
              ) : (
                item.name
              )
            }
            key={item.title + i}
          >
            {childrenItems}
          </SubMenu>
        );
      }
      return null;
    } else {
      return (
        <Menu.Item
          key={item.title + i}
          className={cx({
            'ant-menu-item-selected': location.pathname.startsWith(item.path),
          })}
        >
          {getMenuItemPath(item)}
        </Menu.Item>
      );
    }
  };

  const getMenuItemPath = (item) => {
    const itemPath = conversionPath(item.path);
    const icon = item.icon ? item.icon : null;
    const { target, title } = item;
    // Is it a http link
    if (/^https?:\/\//.test(itemPath)) {
      return (
        <a href={itemPath} target={target}>
          {icon}
          <span>{title}</span>
        </a>
      );
    }
    return (
      <Link
        to={itemPath}
        target={target}
        replace={itemPath === location.pathname}
        onClick={isMobile ? onCollapse : () => {}}
      >
        {icon}
        <span>{title}</span>
      </Link>
    );
  };

  const conversionPath = (path) => {
    if (path && path.indexOf('http') === 0) {
      return path;
    } else {
      return `/${path || ''}`.replace(/\/+/g, '/').replace(/\/:\w+\??/, '');
    }
  };

  const siderBar = (
    <Sider
      className={classnames}
      width={230}
      collapsedWidth={leftCollapsedWidth + 0.1}
      collapsible
      collapsed={!isMobile ? collapsed : false}
      // onCollapse={()=> onCollapse()}
      breakpoint='lg'
      trigger={null}
    >
      <div className='sidebar-left-content'>
        <Menu
          // onClick={this.handleClick}
          // onOpenChange={this.handleOpenChange}
          selectable={false}
          mode='inline'
          theme='light'
        >
          {getNavMenuItems(routes)}
        </Menu>
      </div>
    </Sider>
  );

  return isMobile ? (
    <Drawer
      className='left-sidebar-drawer'
      visible={collapsed}
      placement='left'
      onClose={onCollapse}
      width={230}
      closable={false}
    >
      <div className='navbar-branding'>
        <span className='toggle_sidemenu_l' onClick={() => onCollapse()}>
          <MenuOutlined />
        </span>
        <div className='navbar-brand'>
          {/* <img src='/images/logo.png' alt='logo' /> */}
          <img src={logo} alt='logo-restobares' />
        </div>
      </div>
      {siderBar}
    </Drawer>
  ) : (
    siderBar
  );
}
Sidebar.propTypes = {
  collapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
};
export default Sidebar;
