import React, { useEffect, useState } from 'react'
import { Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './styles.less'

function ImageUploader({ name, form, setDeletedItems, mini, ...props }) {
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (props.fileList) {
      setFileList(props.fileList)
    }
  }, [props.fileList])

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className='image-uploader-text' style={{ marginTop: 8 }}>
        Agregar foto
      </div>
    </div>
  )
  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
    form.setFieldsValue({ [name]: newFileList })
  }

  return (
    <div>
      <ImgCrop rotate grid>
        <Upload
          name={name}
          listType='picture-card'
          accept='.jpg, .png, .jpeg, .jp2'
          // maxCount={1}
          className={classNames('image-uploader', { mini })}
          isImageUrl={(file) => file.thumbUrl || file.url || 'error.jpg'}
          // previewFile={false}
          showUploadList={{
            // showRemoveIcon: false,
            showPreviewIcon: false
          }}
          {...props}
          fileList={fileList}
          onChange={handleChange}
          onRemove={(file) => {
            setDeletedItems((prev) => [...prev, file.thumbUrl])
          }}
        >
          {uploadButton}
        </Upload>
      </ImgCrop>
    </div>
  )
}

ImageUploader.defaultProps = {
  name: 'file'
}

ImageUploader.propTypes = {
  name: PropTypes.string,
  fileList: PropTypes.array,
  hideButton: PropTypes.bool,
  mini: PropTypes.bool,
  loading: PropTypes.bool,
  onRemove: PropTypes.func
}

export default ImageUploader
