import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  Modal,
  Space,
  Tag,
  Image,
  Avatar,
  Table,
  Card
} from 'antd'
import './styles.less'
import {
  CameraOutlined,
  MoreOutlined,
  PlusOutlined,
  ShoppingOutlined,
  StarFilled
} from '@ant-design/icons'
import { useModal } from '@ebay/nice-modal-react'
import { MODAL, MODELS } from 'config/constants'
import Product from 'components/Product'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import {
  useDeleteProductMutation,
  useUpdateProductMutation
} from 'store/services/productApi'
import {
  useDeleteCategoryMutation,
  useUpdateCategoryMutation
} from 'store/services/categoryApi'
import { isMobile } from 'react-device-detect'

const Category = ({
  id,
  name,
  icon,
  onClickNewItem,
  available,
  visible,
  onSetVisible,
  onSetAvailable,
  onEdit,
  onDelete,
  products,
  onRefresh
}) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const productBinModal = useModal(MODAL.ProductBin)
  const actionModal = useModal(MODAL.ActionConfirm)
  const [updateProduct] = useUpdateProductMutation()
  const [deleteProduct] = useDeleteProductMutation()
  const [deleteCategory] = useDeleteCategoryMutation()
  const [updateCategory] = useUpdateCategoryMutation()
  const menu = (
    <Menu>
      <Menu.Item key='0' onClick={onClickNewItem}>
        Crear nuevo producto
      </Menu.Item>
      <Menu.Item
        key='1'
        onClick={() => productBinModal.show({ category: id }).then(onRefresh)}
      >
        Rescatar de la papelera
      </Menu.Item>
    </Menu>
  )

  const handleActionModal = (item, type, action) => {
    actionModal.show({
      item,
      type,
      action,
      onConfirm: async () => {
        const formData = new FormData()
        if (type === MODELS.product) {
          switch (action) {
            case 'FEATURED': {
              formData.set('featured', !item.featured)
              await updateProduct({
                id: item.id,
                formData
              })
              break
            }
            case 'AVAILABLE': {
              formData.set('available', !item.available)
              await updateProduct({
                id: item.id,
                formData
              })
              break
            }
            case 'VISIBLE': {
              formData.set('visible', !item.visible)
              await updateProduct({
                id: item.id,
                formData
              })
              break
            }
            case 'DELETE': {
              await deleteProduct(item.id)
              break
            }
            default:
          }
        } else if (MODELS.category) {
          switch (action) {
            case 'AVAILABLE': {
              await updateCategory({
                id: item.id,
                values: { available: !item.available }
              })
              break
            }
            case 'DELETE': {
              await deleteCategory(item.id)
              break
            }
            default:
          }
        }
        onRefresh()
      }
    })
  }

  const columns = [
    {
      title: <CameraOutlined />,
      align: 'center',
      width: '60px',
      render: (row) => {
        const image = row.item ? row.item.images?.[0] : row.images?.[0]
        return (
          <>
            {image ? (
              <Image src={image} />
            ) : (
              <Avatar shape='square' icon={<ShoppingOutlined />} />
            )}
          </>
        )
      }
    },
    {
      title: <StarFilled />,
      align: 'center',
      width: '60px',
      render: (row) => {
        return (
          <>
            <StarFilled
              style={{ color: row.featured ? 'orange' : '#cccccc' }}
              onClick={row.onSetFeatured}
            />
          </>
        )
      }
    },
    {
      title: 'SKU',
      render: (row) => (row.item ? row.item.sku : row.sku)
    },
    {
      title: 'Nombre',
      render: (row) => (row.item ? row.item.name : row.name)
    },
    {
      title: 'Disponible',
      render: (row) => {
        return (
          <>
            <FontAwesomeIcon
              onClick={row.onSetAvailable}
              icon={row.available ? faCheck : faTimes}
              style={{
                color: row.available ? '#52c41a' : 'red',
                cursor: 'pointer'
              }}
            />
          </>
        )
      }
    },
    {
      title: 'Visible',
      render: (row) => {
        return (
          <>
            <FontAwesomeIcon
              onClick={row.onSetVisible}
              icon={row.visible ? faCheck : faTimes}
              style={{
                color: row.visible ? '#52c41a' : 'red',
                cursor: 'pointer'
              }}
            />
          </>
        )
      }
    },
    {
      width: '40px',
      render: (row) => {
        const item = row.item ? row.item : row
        return (
          <Dropdown
            trigger={['click']}
            placement='bottomRight'
            menu={{
              items: [
                {
                  key: 'available',
                  label: item.available
                    ? 'Marcar como no disponible'
                    : 'Marcar como disponible',
                  onClick: () =>
                    handleActionModal(row, MODELS.product, 'AVAILABLE'),

                  danger: item.available
                },
                {
                  key: 'visible',
                  label: item.visible ? 'Ocultar' : 'Mostrar',
                  onClick: () =>
                    handleActionModal(row, MODELS.product, 'VISIBLE')
                },
                {
                  key: 'edit',
                  label: 'Editar',
                  onClick: item.onEdit
                },
                {
                  key: 'delete',
                  label: 'Eliminar',
                  onClick: item.onDelete,
                  danger: true
                }
              ]
            }}
          >
            <Button type='text' icon={<MoreOutlined />} />
          </Dropdown>
        )
      }
    }
  ]

  const bodyStyles = useMemo(() => {
    const styles = products.length > 0 ? { padding: 0 } : {}
    return styles
  }, [products])

  return (
    <Card
      title={
        <Space>
          {name}{' '}
          {products.length ? <small>{products.length} productos</small> : null}
          {(!available || !visible) && (
            <Col span={24}>
              <Space>
                {!available && <Tag color='red'>No disponible</Tag>}
                {!visible && <Tag>Oculta</Tag>}
              </Space>
            </Col>
          )}
        </Space>
      }
      bodyStyle={bodyStyles}
      extra={
        <>
          <Dropdown
            overlay={menu}
            trigger={['click']}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Button
              size='small'
              type='link'
              style={{ marginRight: 10 }}
              icon={<PlusOutlined />}
              title='Agregar producto'
            ></Button>
          </Dropdown>
          <Button
            size='small'
            type='link'
            icon={<MoreOutlined />}
            onClick={() => setVisibleModal(true)}
          ></Button>
        </>
      }
    >
      <Row>
        <Col span={24}>
          {products?.length ? (
            isMobile ? (
              <Row gutte r={[10, 0]}>
                {products.map((product, index) => (
                  <Col xxl={4} xl={4} lg={6} md={6} key={index}>
                    <Product
                      title={product.name}
                      sku={product.sku}
                      images={product.images}
                      price={product.price}
                      available={product.available}
                      visible={product.visible}
                      onEdit={product.onEdit}
                      onDelete={product.onDelete}
                      onSetAvailable={product.onSetAvailable}
                      onSetVisible={product.onSetVisible}
                      onSetFeatured={product.onSetFeatured}
                      icon={icon}
                      featured={product.featured}
                    />
                  </Col>
                ))}
              </Row>
            ) : (
              <Table
                dataSource={products}
                columns={columns}
                rowKey='id'
                pagination={false}
              />
            )
          ) : (
            'No hay productos en esta categoría'
          )}
        </Col>
      </Row>

      <Modal
        visible={visibleModal}
        closable={false}
        onCancel={() => setVisibleModal(false)}
        footer={false}
        centered
      >
        <div className='buttons-modal'>
          <Button
            type='primary'
            danger={available}
            block
            onClick={() => {
              setVisibleModal(false)
              onSetAvailable()
            }}
          >
            {available ? 'Marcar como no disponible' : 'Marcar como disponible'}
          </Button>
          <Button
            block
            type='text'
            onClick={() => {
              setVisibleModal(false)
              onSetVisible()
            }}
          >
            {visible ? 'Ocultar' : 'Mostrar'}
          </Button>
          <Button
            type='text'
            block
            onClick={() => {
              setVisibleModal(false)
              onEdit()
            }}
          >
            Editar
          </Button>
          <Button
            type='text'
            danger
            block
            onClick={() => {
              setVisibleModal(false)
              onDelete()
            }}
          >
            Eliminar
          </Button>
        </div>
      </Modal>
    </Card>
  )
}

Category.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  icon: PropTypes.string,
  onClickNewItem: PropTypes.func,
  available: PropTypes.bool,
  visible: PropTypes.bool,
  onSetVisible: PropTypes.func,
  onSetAvailable: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  products: PropTypes.array,
  onRefresh: PropTypes.func
}

export default Category
