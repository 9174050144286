import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'config/constants';
import { addQuery } from 'utils';

export const menuApi = createApi({
  reducerPath: 'menuApi',
  baseQuery,
  endpoints: (builder) => ({
    getMenu: builder.query({
      query: ({ restobarId, params }) =>
        `/menu/${restobarId}${addQuery(params)}`,
    }),
  }),
});

export const { useGetMenuQuery } = menuApi;
