import homeRoutes from './homeRoutes';
// import productsRoutes from './productsRoutes';
import contactsRoutes from './contactsRoutes';
import attributesRoutes from './attributesRoutes';
import authRoutes from './authRoutes';
import requisitionsRoutes from './requisitionsRoutes';
import categoriesRoutes from './categoriesRoutes';
import certificationsRoutes from './certificationsRoutes';

export { authRoutes };

const routes = [
  homeRoutes,
  requisitionsRoutes,
  // productsRoutes,
  categoriesRoutes,
  attributesRoutes,
  certificationsRoutes,
  contactsRoutes,
];

export default routes;
