import React from 'react';
import { FileOutlined } from '@ant-design/icons';
import Certifications from 'pages/Certifications';

const certificationsRoutes = {
  title: 'Certificaciones',
  icon: <FileOutlined />,
  Component: Certifications,
  path: '/certificaciones',
  sidebar: true,
};

export default certificationsRoutes;
