import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Input, Modal, Form, Select, Switch } from 'antd'
import './styles.less'
import {
  useCreateCategoryMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation
} from 'store/services/categoryApi'
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react'
import slugify from 'slugify'
import _ from 'lodash'

const SubCategoryAddOrEdit = NiceModal.create(({ category, parentId }) => {
  const [form] = Form.useForm()
  const modal = useModal()
  const [createCategory, { isLoading: creating }] = useCreateCategoryMutation()
  const [updateCategory, { isLoading: updating }] = useUpdateCategoryMutation()
  const { data: categories, isLoading } = useGetCategoriesQuery()

  const selectOptions = useMemo(() => {
    let options = []
    const addCat = (cat) => {
      if (!cat.parentId) {
        options.push({ value: cat.id, label: cat.name })
      }
    }
    if (categories?.length) {
      categories.forEach((cat) => addCat(cat))
    }
    return _.orderBy(options, ['label'], ['asc'])
  }, [categories])

  const handleResolve = () => {
    modal.resolve()
    modal.hide()
  }

  const handleFinish = async (values) => {
    const data = {
      ...values,
      slug: slugify(values.name).toLowerCase(),
      order: 1,
      productRef: values.productRef || null
    }
    if (category) {
      await updateCategory({ id: category.id, values: data }).then(
        handleResolve
      )
    } else {
      console.log('data new', data)
      await createCategory(data).then(handleResolve)
    }
  }

  useEffect(() => {
    if (!modal.visible) return
    category &&
      form.setFieldsValue({
        ...category,
        productRef: category.productRef?.id
      })
    if (parentId) {
      form.setFieldsValue({ parentId: parentId })
    }
  }, [category, parentId, modal.visible, form])

  return (
    <>
      <Modal
        {...antdModal(modal)}
        title={category ? 'Editar subcategoría' : 'Nueva subcategoría'}
        open={modal.visible}
        onCancel={modal.hide}
        afterClose={modal.remove}
        onOk={() => form.submit()}
        getContainer={false}
        okButtonProps={{ loading: creating || updating }}
        okText='Guardar'
      >
        {!isLoading && (
          <Form
            form={form}
            layout='vertical'
            onFinish={(values) => handleFinish(values)}
            initialValues={{
              name: '',
              slug: '',
              parent: null,
              visible: true,
              available: true
            }}
          >
            <Form.Item
              label='Nombre'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'El nombre de la subcategoría es obligatorio'
                }
              ]}
            >
              <Input placeholder='Ej: Platos de fondo' />
            </Form.Item>

            {selectOptions.length ? (
              <Form.Item
                name='parentId'
                label='Selecciona la categoría que contendrá a esta subcategoría'
                rules={[
                  {
                    required: true,
                    message: 'Debes seleccionar una categoría'
                  }
                ]}
              >
                <Select
                  placeholder='Selecciona una categoría'
                  showSearch
                  allowClear
                  disabled={parentId}
                  options={selectOptions}
                  filterOption={(input, option) =>
                    option.label
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                ></Select>
              </Form.Item>
            ) : null}

            <Form.Item
              name='visible'
              label='¿La categoría será visible para los clientes?'
              valuePropName='checked'
            >
              <Switch checkedChildren='Sí' unCheckedChildren='No' />
            </Form.Item>
            <Form.Item
              name='available'
              label='¿La categoría se mostrará como disponible para los clientes?'
              valuePropName='checked'
            >
              <Switch checkedChildren='Sí' unCheckedChildren='No' />
            </Form.Item>
            {category && (
              <Form.Item label='Producto de referencia' name='productRef'>
                <Select
                  showSearch
                  placeholder='Selecciona un producto'
                  allowClear
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={category.products.map((product) => ({
                    label: product.name,
                    value: product.id
                  }))}
                />
              </Form.Item>
            )}
          </Form>
        )}
      </Modal>
    </>
  )
})

SubCategoryAddOrEdit.propTypes = {
  category: PropTypes.object
}

export default SubCategoryAddOrEdit
